import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteAlbumImageModal.module.scss";
import { useDeleteAlbumImageModal } from "./useDeleteAlbumImageModal";

const DeleteAlbumImageModal = ({ deleteModal, setDeleteModal, albumId }) => {
  const {
    selectedAlbumImage,
    handleDelete,
    albumDetails,
    handleClose,
    isDeletingAlbum,
    isDeletingAll,
  } = useDeleteAlbumImageModal({
    setDeleteModal,
    albumId,
  });
  const DeleteModalContent = () => {
    return (
      <>
        <h3 className={Style.delete_title}>{`Delete ${
          selectedAlbumImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
        }`}</h3>
        <p className={Style.delete_content}>
          Remove from Album" keeps it in your library, while "Delete from All"
          moves it to the trash.
        </p>
        <div className={`${Style.delete_btn_wrap} `}>
          {!albumDetails?.default ? (
            <button
              className={`${
                isDeletingAll == "loading" && Style.btn_loader
              } btn-danger`}
              onClick={() => handleDelete("permanent")}
            >
              {isDeletingAll !== "loading" ? " Delete from All" : "Deleting..."}
            </button>
          ) : (
            <button className="btn-outline-gray" onClick={() => handleClose()}>
              Cancel
            </button>
          )}
          <button
            className={`${
              albumDetails?.default ? "btn-danger" : "btn-outline-gray"
            } ${isDeletingAlbum == "loading" && Style.btn_loader}`}
            onClick={() =>
              handleDelete(albumDetails?.default ? "permanent" : "album")
            }
          >
            {isDeletingAlbum !== "loading"
              ? albumDetails?.default
                ? "Delete"
                : "Delete from Album"
              : "Deleting..."}
          </button>
        </div>
      </>
    );
  };

  return (
    <CommonModal
      showModal={deleteModal.popup}
      hide={() => handleClose()}
      className={Style.delete_this_photo_modal}
      title={""}
      content={<DeleteModalContent />}
    />
  );
};

export default DeleteAlbumImageModal;
