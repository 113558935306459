import React, { useState, useEffect, useRef } from "react";
import Style from "../EditProfilePage.module.scss";
import Assets from "../../Layouts/Assets";
import { useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import { useEmailUpdate } from "../../../Logic/useEmailUpdate";
import * as Yup from "yup";

function ComponentEmailUpdateForm({ ClosePopup }) {
  const [NewEmail, setNewEmail] = useState("");
  const [ConfirmEmail, setConfirmEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [passwordType, setPasswordType] = useState("password");

  const {
    EmailChangeSubmit,
    capitalizeFirstLetter,
    success,
    error_message,
    formError,
    submitButtonLabel,
  } = useEmailUpdate();

  function NewValidateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  useEffect(() => {
    // ConfirmValidateEmail(ConfirmEmail);
  }, [NewEmail, ConfirmEmail]);

  const handleVisibility = () => {
    setIsVisible(!isVisible);
    setPasswordType(isVisible ? "text" : "password");

    // setTimeout(() => {
    //   setPasswordType("password");
    //   setIsVisible(false);
    // }, 3000);
  };

  // function ConfirmValidateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = "Required";
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     error = "Invalid email address.";
  //   } else if (NewEmail !== ConfirmEmail) {
  //     error = "Email and Confirm email should be same.";
  //   }
  //   return error;
  // }

  // function validatePassword(value) {
  //   const passwordRegex = new RegExp("^(?=.{6,})");
  //   let error;
  //   if (!value) {
  //     error = "Please enter your password.";
  //   } else if (!passwordRegex.test(value)) {
  //     error = "Enter a valid Passowrd";
  //   }
  //   return error;
  // }

  const passwordRegex = new RegExp("^(?=.{6,})");
  const validationSchema = Yup.object().shape({
    new_email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    confirm_email: Yup.string().when("new_email", {
      is: (new_email) => new_email && new_email.length > 0,
      then: Yup.string()
        .oneOf(
          [Yup.ref("new_email")],
          "Email and Confirm email should be same."
        )
        .required("Confirm Email is required"),
      otherwise: Yup.string().notRequired(),
    }),
    password: Yup.string()
      .matches(passwordRegex, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  return (
    <Formik
      initialValues={{
        new_email: "",
        confirm_email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        // same shape as initial values
        EmailChangeSubmit(values, resetForm);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
      }) => {
        let hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            <div className={Style.edit_profile_info_form}>
              <h3 className={Style.edit_profile_info_title}>Update Email ID</h3>

              <div className="form-group">
                <label className={Style.contact_form_label} for="contact-email">
                  New Email*
                </label>
                <Field
                  name="new_email"
                  className={`${Style.contact_form_input} form-control`}
                  id="contact-email"
                  // placeholder="New email*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                    setFieldValue("new_email", e.target.value);
                  }}
                  validate={NewValidateEmail}
                  autocomplete="new-password"
                  placeholder={"Enter New Email ID"}
                />
                {errors.new_email && touched.new_email && (
                  <div className={Style.field_error}>{errors.new_email}</div>
                )}
              </div>

              <div className="form-group">
                <label className={Style.contact_form_label} for="contact-email">
                  Confirm Email*
                </label>
                <Field
                  name="confirm_email"
                  className={`${Style.contact_form_input} form-control`}
                  id="contact-email"
                  // placeholder="Confirm email*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setConfirmEmail(e.target.value);
                    setFieldValue("confirm_email", e.target.value);
                  }}
                  // validate={ConfirmValidateEmail}
                  autocomplete="new-password"
                  placeholder={"Confirm New Email ID"}
                />
                {errors.confirm_email && touched?.confirm_email && (
                  <div className={Style.field_error}>
                    {errors.confirm_email}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label className={Style.contact_form_label} for="contact-email">
                  Password*
                </label>
                <div className="input-holder">
                  <Field
                    name="password"
                    type={passwordType}
                    // placeholder="Password*"
                    className={`${Style.contact_form_input} form-control`}
                    id="password"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      setPassword(e.target.value);
                    }}
                    // validate={validatePassword}
                    autocomplete="new-password"
                    placeholder={"Enter Your Password"}
                  />

                  <button className="eye" onClick={(e) => handleVisibility(e)}>
                    {isVisible ? (
                      <>
                        <img src={Assets.icon_eye_visible} alt="icon-eye" />
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.192"
                          height="15.192"
                          viewBox="0 0 15.192 15.192"
                        >
                          <g
                            id="Icon_feather-eye-off"
                            data-name="Icon feather-eye-off"
                            transform="translate(1.061 1.061)"
                          >
                            <path
                              id="Path_68039"
                              data-name="Path 68039"
                              d="M11.564,14.282a5.983,5.983,0,0,1-3.529,1.224c-4.159,0-6.535-4.753-6.535-4.753A10.961,10.961,0,0,1,4.506,7.224M6.788,6.143A5.418,5.418,0,0,1,8.035,6c4.159,0,6.535,4.753,6.535,4.753a10.991,10.991,0,0,1-1.283,1.9m-3.992-.636A1.782,1.782,0,1,1,6.776,9.493"
                              transform="translate(-1.5 -4.218)"
                              fill="none"
                              stroke="#a2a4ac"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_68040"
                              data-name="Path 68040"
                              d="M1.5,1.5,14.57,14.57"
                              transform="translate(-1.5 -1.5)"
                              fill="none"
                              stroke="#a2a4ac"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </>
                    )}
                  </button>
                </div>
                {errors.password && touched.password && (
                  <div className={Style.field_error}>{errors.password}</div>
                )}
              </div>
              {(success || formError) && (
                <div className={Style.success_message}>
                  {success && (
                    <span className={Style.success}>
                      {error_message.success}
                    </span>
                  )}
                  {formError && (
                    <span className={Style.error}>{error_message.error}</span>
                  )}
                </div>
              )}
              <div className={Style.edit_profile_info_btn_wrap}>
                <button
                  onClick={handleSubmit}
                  className="btn btn-tertiary"
                  disabled={!hasErrors || !dirty}
                >
                  {submitButtonLabel}
                </button>
                <button
                  onClick={() => {
                    ClosePopup();
                  }}
                  className="btn btn-border-grey"
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default ComponentEmailUpdateForm;
