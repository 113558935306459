import React from 'react';
import { useTranslation } from "react-i18next";

const InformationAndGuidelines = () => {
    const { t } = useTranslation(["guidelines"]);

    return (
        <>
            <div className="inner-page">
                <div className='container'>
                    <h1 className="policies-title">{t("terms_title")}</h1>
                    <div className="policies-date">{t("terms_last_updated")}</div>
                    <div className='admin-content-area'>
                        <p>
                        {t("terms_text_1a")} <a href="http://FamilyPhotosAlbum.com">FamilyPhotosAlbum.com</a>, {t("terms_text_1b")} 
                        </p>
                        <ol>
                            <li>
                                {t("terms_1")}
                            </li>
                            <li>
                            {t("terms_2")}
                            </li>
                            <li>
                            {t("terms_3")} 
                            </li>
                            <li>
                            {t("terms_4")}
                            </li>
                            <li>
                            {t("terms_5")}
                            </li>
                            <li>
                            {t("terms_6")}
                            </li>

                            <li>
                            {t("terms_7")}
                            </li>
                            <li>
                            {t("terms_8")}
                            </li>
                            <li>
                            {t("terms_9")}
                            </li>
                            <li>
                            {t("terms_10")}
                            </li>
                            <li>
                            {t("terms_11")}
                            </li>
                        </ol>

                        <h2>{t("terms_sub_title_1")}</h2>
                        <p>
                        <a className='a' href="https://familyphotosalbum.com">FamilyPhotosAlbum.com</a> {t("terms_12a")} <a className='a' href="https://photofind.in" target='_blank'>Photofind.in</a> {t("terms_12b")}
                        
                        </p>
                        <p>
                        {t("terms_13")}
                        </p>
                        <p>
                        {t("terms_14")}
                        </p>

                        <h2>{t("conclusion_title")}</h2>
                        <p>
                        {t("conclusion_1")}
                        </p>
                        <p>
                            {t("conclusion_2")} <a className='a' href="https://familyphotosalbum.com">FamilyPhotosAlbum.com</a>
                        </p>
                        <i>{t("terms_dated")}</i>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InformationAndGuidelines;