import React from "react";
import Style from "../StaredImages.module.scss";
import { useFilter } from "./useFilter";
import Icons from "../../../Layouts/Icons";

const StaredFilter = ({ closeFavSection }) => {
  const {
    selectAll,
    isNotAllSelected,
    handleSelectAll,
    handleAddToStaredImages,
  } = useFilter();

  const SelectAllBtn = () => {
    return (
      <button
        className={`a-05 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        type="button"
        onClick={() => handleSelectAll()}
      >
        {selectAll && !isNotAllSelected ? (
          <>
            <Icons icon={"action-unselect"} size={17} />
            Deselect All
          </>
        ) : (
          <>
            <Icons icon={"action-select"} size={17} />
            Select All
          </>
        )}
      </button>
    );
  };

  return (
    <div className={Style.favActions}>
      <SelectAllBtn />

      <button
        onClick={() => closeFavSection()}
        className={`${Style.dashboard_actions_close} ${
          Style.dashboard_btn
        } ${"btn btn-border-grey"}`}
      >
        Cancel
      </button>

      <button
        onClick={() => handleAddToStaredImages()}
        className={`${Style.add_btn} ${
          Style.dashboard_btn
        } ${"btn btn-border-secondary"}`}
      >
        Update
      </button>
    </div>
  );
};

export default StaredFilter;
