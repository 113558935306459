import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { saveSlideShow } from "../../../../store/slices/slideshow/slideshowSlice";
import { updateSlideDetails } from "../../../../store/slices/SlideShowDetailSlice";
import { useSlideShowControl } from "../SlideShowControl/useSlideShowControl";
import { useSlideShowEdit1 } from "../useSlideShowEdit1";
import { isEditedSlideShow } from "../../../../store/slices/slideshow/slideshowSlice";

export const useSlideHeader = (slideData, slides) => {
  let navigate = useNavigate();
  const inputFocusRef = useRef(null);
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const slideDetail = useSelector((state) => state.slideShowDetail);
  // const { isEdited, setIsEdited } = useSlideShowEdit1();
  const { isEdited } = useSelector((state) => state.slideshow);

  const handleChange = (e) => {
    dispatch(
      updateSlideDetails({ details: { ...slideData, name: e.target.value } })
    );
  };

  const handleClickGoBack = () => {
    if (isEdited && slides?.length !== 0) {
      toast.warning("Slide show not saved!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      navigate(-1);
    }
  };

  const handleSave = async () => {
    if (slideDetail?.resultData?.slideshow?.name?.length == 0) {
      toast.warning("Slideshow Name is Required", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let imageIds = slideDetail?.resultData.images?.map((item) => item?.id);
      let data = {
        add: [...imageIds],
        name: slideDetail?.resultData?.slideshow?.name,
        effect: slideDetail?.resultData?.slideshow?.effect,
        duration: slideDetail?.resultData?.slideshow?.duration,
        music_id: slideDetail?.resultData?.slideshow?.music_id,
        slideshow_id:
          slideDetail?.resultData?.slideshow?.id == "10101"
            ? ""
            : slideDetail?.resultData?.slideshow?.id,
      };

      const resultAction = await dispatch(saveSlideShow(data));
      if (saveSlideShow.fulfilled.match(resultAction)) {
        toast.success("Slideshow saved!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(isEditedSlideShow(false));
      }
    }
  };

  const enableEditing = () => {
    setIsEditable(true);
    dispatch(isEditedSlideShow(true));
    let inputValue = inputFocusRef.current.value;
    inputFocusRef.current.value = "";
    inputFocusRef.current.value = inputValue;
    setTimeout(() => {
      inputFocusRef.current.focus();
    }, 10);
  };

  return {
    handleChange,
    inputFocusRef,
    isEditable,
    setIsEditable,
    enableEditing,
    handleClickGoBack,
    handleSave,
    slideDetail,
  };
};
