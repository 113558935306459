import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  isDeletingAlbum: "idle",
  isDeletingAll: "idle",
  error: null,
  selectedAlbum: "",
  resultData: {},
  imageStatus: "idle",
};

export const getAlbumItem = createAsyncThunk(
  "albumDetails/getAlbumItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMoreAlbumDetailImages = createAsyncThunk(
  "albumDetails/loadMoreAlbumDetailImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAlbumImages = createAsyncThunk(
  "albumDetails/deleteAlbumImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-images`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrangeAlbum = createAsyncThunk(
  "albumDetails/reArrangeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album-image-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const imageAddToAlbum = createAsyncThunk(
  "albumDetails/imageAddToAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const albumDetailsSlice = createSlice({
  name: "albumDetails",
  initialState,
  reducers: {
    handleSelectedAlbum: (state, action) => {
      state.selectedAlbum = action.payload;
    },

    removeAlbumImagesFromState: (state, action) => {
      let AlbumImages = current(
        state.resultData.data[state.selectedAlbum].images
      );

      let filterAlbumImages = AlbumImages?.filter(
        (item, index) => !action.payload.includes(item.id)
      );

      state.resultData.data[state.selectedAlbum] = {
        ...state.resultData.data[state.selectedAlbum],
        images: filterAlbumImages,
        total: filterAlbumImages?.length,
      };
    },

    rearrange: (state, action) => {
      let draggedIndex = state.resultData.data[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.draggedItem.id);
      let changedIndex = state.resultData.data[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.changedItem.id);

      state.resultData.data[state.selectedAlbum].images[draggedIndex].position =
        action.payload.changedItem.position;

      state.resultData.data[state.selectedAlbum].images[changedIndex].position =
        action.payload.draggedItem.position;

      let data1 =
        state.resultData.data[state.selectedAlbum].images[changedIndex];
      let data2 =
        state.resultData.data[state.selectedAlbum].images[draggedIndex];

      state.resultData.data[state.selectedAlbum].images[draggedIndex] = data1;
      state.resultData.data[state.selectedAlbum].images[changedIndex] = data2;
    },

    updateAlbumUploadedImages: (state, action) => {
      let currentAlbumId = localStorage?.getItem("currentAlbumId");

      state.resultData.data[state?.selectedAlbum] = {
        ...state?.resultData?.data[currentAlbumId],
        images: [
          action?.payload,
          ...state.resultData?.data[currentAlbumId]?.images,
        ],
      };
    },

    updateAlbumDetailFavorite: (state, action) => {
      if (state?.resultData?.data) {
        let index = current(
          state?.resultData?.data[action.payload.album_Id]
        ).images.findIndex((obj) => obj.id === action?.payload?.id);
        if (index != -1) {
          state.resultData.data[action.payload.album_Id].images[
            index
          ].favourite = action?.payload?.favorite;
        }
      }
    },
  },

  extraReducers: {
    [getAlbumItem.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAlbumItem.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = {
        data: {
          ...state.resultData.data,
          [state.selectedAlbum]: { ...action.payload.data.result },
        },
      };
    },
    [getAlbumItem.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteAlbumImages.pending]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "loading";
      } else {
        state.isDeletingAll = "loading";
      }
    },
    [deleteAlbumImages.fulfilled]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "succeeded";
      } else {
        state.isDeletingAll = "succeeded";
      }
    },
    [deleteAlbumImages.rejected]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "failed";
      } else {
        state.isDeletingAll = "failed";
      }
    },
    [loadMoreAlbumDetailImages.pending]: (state, action) => {
      state.imageStatus = "loading";
    },
    [loadMoreAlbumDetailImages.fulfilled]: (state, action) => {
      state.imageStatus = "successful";

      state.resultData.data[action.payload.data.result.album.id].images = [
        ...state.resultData.data[action.payload.data.result.album.id].images,
        ...action.payload.data.result.images,
      ];
      state.resultData.data[action.payload.data.result.album.id].current_page =
        action.payload.data.result.current_page;
    },
    [imageAddToAlbum.pending]: (state, action) => {
      state.status = "loading";
    },

    [imageAddToAlbum.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData.data[state.selectedAlbum].images =
        action.payload.data.result.images;
    },

    [imageAddToAlbum.rejected]: (state, action) => {
      state.status = "failed";
    },

    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },
    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = "";
        if (action.payload.data.result.id) {
          imgIndex = current(
            state.resultData.data[state.selectedAlbum].images
          ).findIndex((obj) => obj.id === action.payload.data.result.id);
        } else {
          imgIndex = current(
            state.resultData.data[state.selectedAlbum].images
          ).findIndex((obj) => obj.id === action.payload.data.result.image_id);
        }
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags;
          state.resultData.data[state.selectedAlbum].images[imgIndex].tags =
            tagData;
        }
      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = state.resultData.data[
          state.selectedAlbum
        ].images.findIndex((obj) => obj.id === action.payload.data.result.id);
        let tagData = action.payload.data.result.tags;
        state.resultData.data[state.selectedAlbum].images[imgIndex].tags =
          tagData;
      }
    },
    ["tags/addDescription/fulfilled"]: (state, action) => {
      if (state.selectedAlbum) {
        let imgIndex = current(
          state.resultData.data[state.selectedAlbum].images
        ).findIndex((obj) => obj.id === action.meta.arg.image_id);
        if (imgIndex !== -1) {
          state.resultData.data[state.selectedAlbum].images[
            imgIndex
          ].description = action.meta.arg.description;
        }
      }
    },
  },
});
export const {
  handleSelectedAlbum,
  removeAlbumImagesFromState,
  rearrange,
  updateAlbumUploadedImages,
  updateAlbumDetailFavorite,
} = albumDetailsSlice.actions;
export default albumDetailsSlice.reducer;
