import React, { useState, useLayoutEffect, memo } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import Style from "./GalleryFilters.module.scss";
import LeftFilteredCategory from "./LeftFilteredCategory";
import RightFilteredCategory from "./RightFilteredCategory";
import { useDispatch, useSelector } from "react-redux";
import LeftArchiveFilteredCategory from "./LeftArchiveFilteredCategory";
import RightArchiveFilteredCategory from "./RightArchiveFilteredCategory";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const GalleryFilters = ({ editGallery }) => {
  const [width, height] = useWindowSize();
  const { currentTab, currentPage } = useSelector((state) => state.gallery); //FIXME

  return (
    <div className={Style.gallery_filters_wrap}>
      <div className={Style.gallery_filters}>
        <LeftFilteredCategory />
      </div>
    </div>
  );
};

export default memo(GalleryFilters);
