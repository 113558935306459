import Style from "./ImageListMasonry.module.scss";
import ImageThumb from "../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";
import useImageListMasonry from "./useImageListMasonry";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import { useQuickSlideShowActions } from "../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import { useFullscreenPreviewActions } from "../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import FullscreenPreviewNew from "../Gallery/FullScreenPreviewNew";
import RightClickModal from "./RightClickModal";
import { useCustomClick } from "./useCustomClick";
import { openUploadModalAction } from "../../store/slices/Gallery/fileUploadSlce";
import { useDispatch, useSelector } from "react-redux";
import AddToAlbumModal from "../Gallery/GalleryFilters/RightFilteredCategory/AddToAlbumModal";
import ImageDeleteModal from "../ImageDeleteModal";
import ShareModal from "../ShareModal";
import { useSocial } from "../../Logic/useSocial";
import { useLocation } from "react-router-dom";
import QuickSlideShow1 from "../Slideshow/QuickSlideShow1";
import { useEffect } from "react";
import { useStopWindowScroll } from "../../Logic/useStopWindowScroll";
import { useKey, useSingleKey } from "../../utils/KeyBoardShortCuts";
import PDFViewer from "../PDFViewer";
import { usePDFViewer } from "./usePDFViwer";
// import DocumentThumb from "./DocumentListContainer/DocumentThumb";

const ImageListMasonry = ({
  Images,
  loadMore,
  NodataContent,
  options,
  moveListItem,
  status,
  albumData,
  frames,
  path,
  mode,
  total,
  disableControls,
  isShare,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { observer, imgRootRef, imgItemRef, breakpointColumnsObj } =
    useImageListMasonry({ loadMore, Images });

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView, setShowFullView);

  const {
    show,
    points,
    selectedItem,
    handleClickContextMenu,
    addToAlbum,
    setAddToAlbum,
    DeleteModal,
    setDeleteModal,
  } = useCustomClick();

  const { pdfOptions, handleOpenPdf, handleClosePdf } = usePDFViewer();
  const { results } = useSelector((state) => state.search);

  const {
    secretToken,
    openShare,
    setOpenShare,
    handleShareItemOnCustomRightClick,
  } = useSocial();

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

    let slideData = {
      images:
        quickSlideShow?.slideActions?.type == "all"
          ? Array.isArray(Images) 
            ? Images?.filter((item) => item?.file_type !== 2)
            : []
          : selectedImageForSlideShow,
      effect: "zoom-out",
      music: quickSlideShow.musicList,
    };

    let previewData = {
      images: Array.isArray(Images) 
        ? Images?.filter((item) => item?.file_type !== 2) 
        : [],
      pathFrom: "gallery",
      section: "all",
    };
  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(showFullView.isOpen);
  }, [showFullView.isOpen]);

  const handleOpenPreview = (a, b, image, i, album) => {
    if (image?.file_type == 2) {
      handleOpenPdf(image);
    } else {
      if (Images?.some((item) => item.file_type == 2)) {
        let filterAlbumsData = Images?.filter((item) => item?.file_type !== 2);
        let tempAlbumIndex = filterAlbumsData?.findIndex((a) => a.id == b);
        openPreview(tempAlbumIndex, b, image, tempAlbumIndex);
      } else {
        openPreview(a, b, image, i);
      }
    }
  };

  return (
    <div ref={imgRootRef} data-masonry='{"percentPosition": true }'>
      {
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Images?.map((image, i) => {
            const lastIndex = Images?.length - 20;
            return (
              <div
                id={i}
                key={i}
                ref={lastIndex == i ? imgItemRef : null}
                onMouseMove={(e) => {}}
                onContextMenu={(e) => {
                  handleClickContextMenu(e, image);
                }}
                className={`${Style.image_container} ${
                  options?.isRearrange ? Style.shake_active : ""
                }`}
              >
                <ImageThumb
                  handleOpenImageFullView={(a, b) =>
                    handleOpenPreview(a, b, image, i, albumData)
                  }
                  key={image.id}
                  image={image}
                  //   showSelect={showSelect}
                  i={i}
                  moveListItem={moveListItem}
                  //   slideshow={slideshow}
                  handleDeleteAction={{}}
                  hasTray={true}
                  options={options}
                  albumData={albumData}
                  frame={albumData?.frames?.image}
                  mode={mode}
                  disableControls={disableControls}
                  isShare={isShare}
                />
              </div>
            );
          })}
        </Masonry>
      }

      <AddToAlbumModal addToAlbum={addToAlbum} setAddToAlbum={setAddToAlbum} />
      <ImageDeleteModal
        DeleteModal={DeleteModal}
        setDeleteModal={setDeleteModal}
      />

      {Images?.length == 0 &&
        status == "succeeded" &&
        NodataContent?.title != undefined && (
          <GalleryBlankPage
            title={NodataContent?.title}
            description={NodataContent?.description}
            buttonText={NodataContent?.buttonText}
            handleClick={() =>
              dispatch(openUploadModalAction({ data: true, path: path }))
            }
          />
        )}
      {show && currentPath == "" && (
        <RightClickModal
          points={points}
          selectedItem={selectedItem}
          setAddToAlbum={setAddToAlbum}
          DeleteModal={DeleteModal}
          setDeleteModal={setDeleteModal}
          handleOpenShare={handleShareItemOnCustomRightClick}
        />
      )}
      {showFullView.isOpen && previewData?.images?.length > 0 && (
        <FullscreenPreviewNew
          previewObj={previewData}
          path={path}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
          total={total}
          disableControls={disableControls}
          frames={albumData?.frames}
          isShare={isShare}
          isPhotoFindAlbum={albumData?.photofind_album}
          albumId={albumData?.id}
        />
      )}

      {quickSlideShow?.slideActions?.isOpen &&
        slideData?.images?.length > 0 && (
          <QuickSlideShow1
            slideObj={slideData}
            close={closeQuickSlideShow}
            initialIndex={showFullView?.index}
          />
        )}

      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="zoomView"
        secretToken={secretToken}
        path={"view-content"}
      />

      {pdfOptions?.isOpen && pdfOptions?.url && (
        <PDFViewer
          url={pdfOptions?.url}
          data={pdfOptions}
          handleClose={handleClosePdf}
        />
      )}

      {status == "loading" ? <DashboardLoader data={Images} /> : ""}
    </div>
  );
};

export default ImageListMasonry;
