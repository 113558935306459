import { useEffect, useRef, useState } from "react";
import Assets from "../../Layouts/Assets";
import CommonModal from "../../Modals/commonModal";
import Style from "./AlbumUnlockModal.module.scss";
import { useAlbumUnlockModal } from "./useAlbumUnlockModal";

const AlubmUnlockModal = ({ unlockModal, setUnlockModal }) => {
  const {
    ele_password,
    handlePasswordChange,
    handleBlur,
    ShowPassword,
    handleUnlock,
    formData,
    handleClickShowPassword,
    handleClose,
  } = useAlbumUnlockModal({ unlockModal, setUnlockModal });

  return (
    <CommonModal
      showModal={unlockModal.popup}
      hide={() => handleClose()}
      className={Style.delete_account_modal}
      title={""}
      content={
        <div className={Style.delete_account}>
          <h3 className={Style.delete_account_title}>Unlock Album</h3>
          <p className={Style.delete_account_description}>
            Enter password to unlock the album.
          </p>

          <div className={`form-group`}>
            <label htmlFor="unlock-password">Password</label>
            <div className={Style.input_wrap}>
              <input
                className={"form-control"}
                placeholder="Password"
                id={"unlock-password"}
                type={"password"}
                ref={ele_password}
                autoComplete="new-password"
                value={formData?.password}
                onChange={(e) => handlePasswordChange(e)}
                onBlur={(e) => handleBlur(e)}
              />

              <div
                className={Style.icon_visibility}
                onClick={() => {
                  handleClickShowPassword();
                }}
              >
                {!formData?.isPassVisible ? (
                  <img src={Assets.icon_eye_visible} alt="" />
                ) : (
                  <img src={Assets.icon_eye} alt="" />
                )}
              </div>
            </div>
            <span className={Style.lockerror}>
              {formData?.isBlur &&
              !formData?.isValid &&
              formData?.error?.message ? (
                <p>{formData?.error?.message}</p>
              ) : (
                ""
              )}
              {/* {message && <p>{message}</p>} */}
            </span>
          </div>

          <div className={Style.delete_account_btn_wrap}>
            <button
              disabled={formData?.password?.length < 6}
              className={`${"btn btn-primary"} 
                ${Style.otp_continue_btn_loader}`}
              onClick={() => handleUnlock()}
            >
              Unlock
            </button>
          </div>
        </div>
      }
    />
  );
};

export default AlubmUnlockModal;
