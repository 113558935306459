import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  newMemberStatus: "idle",
  isFetchingHistory: "idle",
  treeData: [],
  defaultGenderImage: [],
  versionHistory: [],
};

export const getFamilyTreeData = createAsyncThunk(
  "familyTree/getFamilyTreeData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/show-family", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFamilyDetails = createAsyncThunk(
  "familyTree/getFamilyDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/show-family", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addMemberToTree = createAsyncThunk(
  "familyTree/addMemberToTree",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    const data = { ...params, gender: parseInt(params.gender) };

    try {
      const response = await api.post(
        "/create-profile",
        params.gender ? data : params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeMemberFromTree = createAsyncThunk(
  "familyTree/removeMemberFromTree",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/delete-member", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "familyTree/updateProfilePicture",
  async (params, { rejectWithValue }) => {
    const data = { ...params, image: params.img };
    delete data.img;
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/create-profile-pic", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGenderImage = createAsyncThunk(
  "familyTree/getGenderImage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/list-default-images");

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVersionHistories = createAsyncThunk(
  "familyTree/getVersionHistories",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/list-histories");

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentVersionHistory = createAsyncThunk(
  "familyTree/getCurrentVersionHistory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/single-history/${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVersionHistory = createAsyncThunk(
  "familyTree/deleteVersionHistory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/delete-version", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const restoreVersion = createAsyncThunk(
  "familyTree/restoreVersion",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/restore-version", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addVersionTitle = createAsyncThunk(
  "familyTree/addVersionTitle",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/title-updation", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const familyTreeSlice = createSlice({
  name: "familyTree",
  initialState,
  extraReducers: {
    [getFamilyTreeData.pending]: (state) => {
      state.status = "loading";
    },
    [getFamilyTreeData.fulfilled]: (state, action) => {
      state.status = "success";
      state.treeData = action.payload?.data?.result;
    },
    [getFamilyTreeData.rejected]: (state, action) => {
      state.status = "error";
    },
    [addMemberToTree.pending]: (state, action) => {
      state.newMemberStatus = "loading";
    },
    [addMemberToTree.fulfilled]: (state, action) => {
      state.newMemberStatus = "success";
    },
    [addMemberToTree.rejected]: (state, action) => {
      state.newMemberStatus = "error";
    },

    [getGenderImage.pending]: (state, action) => {
      state.defaultImageMessage = "loading";
    },
    [getGenderImage.fulfilled]: (state, action) => {
      state.defaultImageMessage = "success";
      state.defaultGenderImage = action.payload?.data?.result.default_image;
    },
    [getGenderImage.rejected]: (state, action) => {
      state.defaultImageMessage = "error";
    },

    [getVersionHistories.pending]: (state, action) => {
      state.isFetchingHistory = "loading";
    },
    [getVersionHistories.fulfilled]: (state, action) => {
      state.isFetchingHistory = "success";
      state.versionHistory = action.payload?.data?.result;
    },
    [getVersionHistories.rejected]: (state, action) => {
      state.isFetchingHistory = "error";
    },

    [getCurrentVersionHistory.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCurrentVersionHistory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getCurrentVersionHistory.rejected]: (state, action) => {
      state.status = "error";
    },

    [deleteVersionHistory.pending]: (state, action) => {
      state.isDeletingHistory = "loading";
    },
    [deleteVersionHistory.fulfilled]: (state, action) => {
      state.isDeletingHistory = "success";
    },
    [deleteVersionHistory.rejected]: (state, action) => {
      state.isDeletingHistory = "error";
    },

    // restoreVersion

    [restoreVersion.pending]: (state, action) => {
      state.isRestoringVersion = "loading";
    },
    [restoreVersion.fulfilled]: (state, action) => {
      state.isRestoringVersion = "success";
    },
    [restoreVersion.rejected]: (state, action) => {
      state.isRestoringVersion = "error";
    },

    [addVersionTitle.pending]: (state, action) => {
      state.isFetchingVersionTitle = "loading";
    },
    [addVersionTitle.fulfilled]: (state, action) => {
      state.isFetchingVersionTitle = "success";
    },
    [addVersionTitle.rejected]: (state, action) => {
      state.isFetchingVersionTitle = "error";
    },

  },
});

export default familyTreeSlice.reducer;
