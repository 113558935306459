import Style from "./PreLoginMenu.module.scss";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import SignInModal from "../../../Modals/SignInModal";
import RegisterModal from "../../../Modals/RegisterModal";
import ForgotPasswordModal from "../../../Modals/ForgotPasswordModal";
import OTPVerificationModal from "../../../Modals/OTPVerificationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Assets from "../../Assets";
import { useRegister } from "../../../../Logic/useRegister";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  changeScreen,
  authModalHandler,
  storeCurrentAuthData,
} from "../../../../store/slices/authSlice";
import CommonModal from "../../../Modals/commonModal";
import AuthenticationModal from "../../../Modals/AuthenticationModal";
import avatar from "../../../../assets/avatar.png";
import { clearProfileState } from "../../../../store/slices/userprofileSlice";
import useAuthChecker from "../../../../Logic/useAuthChecker";
import { clearGalleryState } from "../../../../store/slices/gallerySlice";
import { useLanguage } from "../../../../Logic/useLanguage";
import { useUserChecker } from "../../../../Logic/AuthChecker/useUserChecker";
import { useAuthHandler } from "../../../../Logic/Auth/useAuthHandler";
import { usePurchasedChecker } from "../../../../Logic/AuthChecker/usePurchaseChecker";
import MobilePhoneModal from "../../../Modals/MobilePhoneModal";
import AccountListModal from "../../../Modals/AccountListModal";
import EmailPasswordModal from "../../../Modals/AccountListModal/EmailPasswordModal";
import Icons from "../../Icons";

const EditModals = lazy(() => import("../../../Modals/Modals"));

const PreLoginMenu = ({ setShowSignIn }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();

  const { userdata } = useSelector((state) => state.userprofile);
  const [openModal, setOpenModal] = useState(false);
  const { isAuthCompleted } = useAuthChecker();
  const [searchBar, setSearchBar] = useState(false);

  const { registerData, currentScreen, openAuthModal, loginCreateOtpStatus } =
    useSelector((state) => state.auth);

  const { isLoadingProfile } = useSelector((state) => state.userprofile);

  const { setShowFP, registerStatus, loginStatus } = useRegister();
  const { handleGetProfileDetails } = useUserChecker();

  const {
    handleClickRegister,
    handleClickLogin,
    handleClickFP,
    handleClose,
    handleLogout,
  } = useAuthHandler();

  const {
    availableLanguage,
    language,
    setLanguage,
    languageSwitch,
    flag,
    setFlag,
  } = useLanguage();

  const { planData, isPlanActive } = usePurchasedChecker();

  useEffect(() => {
    if (registerData?.name && userdata?.profile_image == undefined) {
      handleGetProfileDetails();
    }
  }, []);

  const { i18n, t } = useTranslation(["common"]);
  let currLan = localStorage.getItem("i18nextLng").split("-")[0];

  useEffect(() => {
    if (availableLanguage?.length > 0) {
      let currentFlag = availableLanguage?.filter(
        (item) => item?.locale == currLan
      );
      setFlag(currentFlag[0]?.flag);
      setLanguage(currentFlag[0]?.name);
    }
  }, [availableLanguage, currLan]);
  const { mobileVerifyData } = useSelector((state) => state.auth);

  const renderScreen = (page) => {
    switch (page) {
      // case "mobileRegister":
      //   return <MobilePhoneModal />;
      //   break;
      case "register":
        return (
          <RegisterModal
            handleClickLogin={handleClickLogin}
            handleClickFP={handleClickFP}
            status={registerStatus}
            handleClose={handleClose}
          />
        );
        break;
      case "login":
        return (
          <SignInModal
            setShowSignIn={setShowSignIn}
            handleClickRegister={handleClickRegister}
            handleClickFP={handleClickFP}
            status={loginStatus}
            loginWithPhoneStatus={loginCreateOtpStatus}
          />
        );
        break;
      case "forgotPassword":
        return (
          <ForgotPasswordModal
            setShowSignIn={setShowSignIn}
            setShowFP={setShowFP}
          />
        );
      case "otp":
        return <OTPVerificationModal />;

      case "accountListModal":
        return <AccountListModal />;
      case "emailPasswordModal":
        return <EmailPasswordModal handleClickFP={handleClickFP} />;
    }
  };

  const handleClickLogo = () => {
    dispatch(authModalHandler(false));
    dispatch(changeScreen(""));
    navigate("/");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScrollToTop = (route) => {
    location.pathname === `/${route ? route : ""}` &&
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleCheckPath = (path) => {
    if (isAuthCompleted === false) {
      handleClickLogin();
    } else if (isAuthCompleted && !isPlanActive) {
      navigate("/choose-plan");
    } else {
      navigate(path);
    }
  };
  const currentLanguage = (language) => {
    localStorage.setItem("currentLanguage", language);
  };

  const handleCloseAuthModal = () => {
    // document.querySelectorAll(".modal input")[0].blur();

    dispatch(authModalHandler(false));
    dispatch(storeCurrentAuthData({}));
  };

  return (
    <>
      <div className={Style.page_loader}></div>
      <header
        className={`${Style.prelogin_header} ${
          isAuthCompleted ? Style.logged_in_user : ""
        }`}
      >
        <div className="container">
          <div
            className={`${Style.prelogin_header_inner_wrap} ${
              searchBar ? Style.search_open : ""
            }`}
          >
            <figure
              className={`${Style.prelogin_header_logo} anim header-anim`}
            >
              <a title={"Family Photos"} onClick={() => handleClickLogo()}>
                <img
                  src={
                    localStorage.getItem("theme") == "light"
                      ? Assets.fpa_main_logo_dark
                      : Assets.fpa_main_logo
                  }
                  alt="Family Photos"
                />
              </a>
            </figure>
            <div className={Style.prelogin_header_nav}>
              <div className={Style.prelogin_header_nav_links}>
                <ul className="anim header-anim">
                  <li className={location.pathname == "/" ? Style.active : ""}>
                    <Link to={"/"} onClick={() => handleScrollToTop()}>
                      {t("home")}
                    </Link>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleCheckPath("/dashboard");
                      }}
                    >
                      {t("upload_photos")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleCheckPath("/dashboard/family-tree");
                      }}
                    >
                      {t("family_tree")}
                    </a>
                  </li>
                  <li
                    className={
                      location.pathname == "/contact" ? Style.active : ""
                    }
                  >
                    <Link
                      to={"/contact"}
                      onClick={() => handleScrollToTop("contact")}
                    >
                      {t("contact")}
                    </Link>
                  </li>
                  {isAuthCompleted ? (
                    ""
                  ) : (
                    <>
                      <li>
                        <button onClick={() => handleClickRegister()}>
                          {t("register")}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            handleClickLogin();
                            // dispatch(changeScreen("accountListModal"));
                          }}
                        >
                          {t("login")}
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <Dropdown
                className={`${Style.prelogin_header_nav_language} anim header-anim`}
              >
                <Dropdown.Toggle>
                  <figure className={Style.dropdown_flag}>
                    <img
                      src={flag}
                      alt="flag_image"
                      className={Style.flag_Img}
                    />
                  </figure>
                  <div className={Style.dropdown_label}>{language}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {availableLanguage.map((lang, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          currentLanguage(lang.name);
                          languageSwitch(
                            lang.name,
                            lang.flag,
                            lang.locale,
                            lang
                          );
                        }}
                        key={lang.id}
                      >
                        <span
                          className="icon-tick"
                          style={{
                            visibility:
                              lang?.name ===
                              localStorage.getItem("currentLanguage")
                                ? "visible"
                                : lang?.name === "english" &&
                                  localStorage.getItem("currentLanguage") === ""
                                ? "visible"
                                : "hidden",
                            marginRight: "10px",
                          }}
                        >
                          <Icons icon={"icon-tick"} size={15} color={"green"} />
                        </span>
                        {lang.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              {isAuthCompleted ? (
                <Dropdown>
                  {isLoadingProfile == "loading" ? (
                    <div className={Style.shimmerBox}>
                      <div className={Style.profile_name_round}></div>
                      <div className={Style.profile_name_shimmer}></div>
                    </div>
                  ) : (
                    <Dropdown.Toggle className={Style.profile_dropdown}>
                      <figure className={Style.profile_image}>
                        <img
                          src={
                            userdata?.profile_image?.thumbnail
                              ? userdata?.profile_image?.thumbnail
                              : avatar
                          }
                          alt="profile-pic"
                        />
                      </figure>
                      <div className={Style.profile_details}>
                        <span className={Style.username}>{userdata?.name}</span>
                      </div>
                    </Dropdown.Toggle>
                  )}

                  <Dropdown.Menu>
                    {location.pathname == "/" ||
                    location.pathname == "/get-started" ||
                    location.pathname == "choose-plan" ? (
                      <Dropdown.Item as={Link} to="/dashboard/user-profile">
                        My Profile
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item as={Link} to="/">
                        Home
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div
                  className={`${Style.prelogin_header_nav_getdemo} anim header-anim`}
                >
                  <Button
                    className={`btn btn-border ${Style.prelogin_header_nav_getdemo_btn}`}
                  >
                    <Link to="/quick-demo"></Link>
                    {t("get_a_demo")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {currentScreen ? (
          <AuthenticationModal
            showModal={openAuthModal}
            setShowModal={() => handleCloseAuthModal()}
          >
            {renderScreen(currentScreen)}
          </AuthenticationModal>
        ) : (
          ""
        )}
        <CommonModal
          showModal={openModal}
          hide={() => setOpenModal(false)}
          className="modal-width logout-modal"
          content={
            <>
              <div className={Style.logoutSection}>
                <div className={`${Style.title} mb-4`}>
                  Are You Sure To Logout?
                </div>
                <div
                  className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
                >
                  <button
                    onClick={() => handleLogout()}
                    className="btn btn-primary"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="btn btn-light"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          }
        />
      </header>
    </>
  );
};

export default PreLoginMenu;
