import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/DashboardPage";
import Invitations from "../components/Invitations";
import ImageGallery from "../components/Gallery/ImageGallery";
import Favorites from "../components/Favorites";
import Gallery from "../components/Gallery";
import Album from "../components/Album";
import Slideshow from "../components/Slideshow";
import Archive from "../components/Archive/index";
import { SlideShowEditNew } from "../components/Slideshow/SlideShowEditNew";
import FamilyTreePage from "../components/FamilyTreePage";
import { MyRequests } from "../components/MyRequests";
import Preference from "../components/Preference";
import { TellUsAbout } from "../components/TellUsAboutYourFamily";
import GetStartedPage from "../pages/GetStartedPage";
import FamilyTreeForm from "../components/FamilyTreePage/FamilyTreeForm";
import UserProfile from "../components/UserProfile";
import UserProfileOverview from "../components/UserProfile/UserProfileOverview";
import UserProfileGallery from "../components/UserProfile/UserProfileGallery";
import UserProfileViewOnTree from "../components/UserProfile/UserProfileViewOnTree";
import UserProfileFamilyMembers from "../components/UserProfile/UserProfileFamilyMembers";
import ChoosePlanPage from "../pages/ChoosePlanPage";
import StyleGuidePage from "../pages/StyleGuidePage";
import SearchResults from "../components/SearchResults";
import EditProfilePage from "../components/EditProfilePage";
import PreferenceMenu from "../components/PreferenceMenu";
import KeyboardShortcutsList from "../components/KeyboardShortcutsList";
import ContactPage from "../pages/ContactPage";
import LegalPages from "../pages/LegalPages";
import ErrorPage from "../pages/ErrorPage";
import DemoPage from "../pages/DemoPage";
import ImageViewer from "../components/ImageViewer";
import ImageEditComponent from "../components/ImageEditComponent";
import Trash from "../components/Trash";
import ViewContent from "../pages/ViewContent";
import DemoVideo from "../pages/DemoVideo";
import TestPayment from "../components/TestPayment";
import HdfcSuccess from "../components/HdfcSuccess";
import AlbumDetailView from "../components/NewAlbum/AlbumDetailView";
import ShareUserProfile from "../components/UserProfile/ShareUserProfile";
import StarredImages from "../components/UserProfile/ShareUserProfile/StarredImages";
import VideoGallery from "../components/VideoGallery";
import ShareAlbum from "../components/Album/ShareAlbum";

const DashobardRoute = {
  name: "DashBoard",
  path: "/dashboard",
  element: <DashboardPage />,
  Children: [
    {
      name: "Gallery",
      path: "",
      element: <Gallery />,
      grandChildren: [
        {
          name: "imageGallery",
          path: "",
          element: <ImageGallery />,
        },
        {
          name: "favorites",
          path: "favorites",
          element: <Favorites />,
        },
        {
          name: "album",
          path: "album",
          element: <Album />,
        },
        {
          name: "slideshow",
          path: "slideshow",
          element: <Slideshow />,
        },
        {
          name: "albumItem",
          path: "album/:albumId",
          element: <AlbumDetailView />,
        },
        {
          name: "hidden",
          path: "hidden",
          element: <Archive />,
        },
        {
          name: "trash",
          path: "trash",
          element: <Trash />,
        },
      ],
    },
    {
      name: "searchresult",
      path: "searchresult/:query/:option",
      element: <SearchResults />,
    },
    {
      name: "imageview",
      path: "imageview/:media_id",
      element: <ImageViewer />,
    },
    {
      name: "user-profile",
      path: "user-profile",
      element: <UserProfile />,
      grandChildren: [
        {
          name: "overView",
          path: "",
          element: <UserProfileOverview />,
        },

        {
          name: "gallery",
          path: "gallery",
          element: <UserProfileGallery />,
        },

        {
          name: "view-on-tree",
          path: "view-on-tree",
          element: <UserProfileViewOnTree />,
        },
        {
          name: "members",
          path: "members",
          element: <UserProfileFamilyMembers />,
        },
      ],
    },
    {
      name: "my-videos-links",
      path: "my-videos-links",
      element: <VideoGallery />,
    },
    {
      name: "img-edit",
      path: "image-edit/:path/:itemId/:image_id",
      element: <ImageEditComponent />,
    },

    {
      name: "slideshowEdit",
      path: "slideshow/edit",
      element: <SlideShowEditNew />,
    },

    {
      name: "slideshowEdit",
      path: "slideshow/edit/:id",
      element: <SlideShowEditNew />,
    },

    {
      name: "familyTree",
      path: "family-tree",
      element: <FamilyTreePage />,
    },
    {
      name: "requests",
      path: "requests",
      element: <MyRequests />,
    },
    {
      name: "preferences",
      path: "preferences",
      element: <Preference />,
      grandChildren: [
        {
          name: "preference-menu",
          path: "",
          element: <PreferenceMenu />,
        },
        {
          name: "update-info",
          path: "update-info",
          element: <EditProfilePage />,
        },
        {
          name: "keybindings",
          path: "keybindings",
          element: <KeyboardShortcutsList />,
        },
      ],
    },
    {
      name: "Invitations",
      path: "invitations",
      element: <Invitations />,
    },

    {
      name: "tellusabout",
      path: "tellusabout",
      element: <TellUsAbout />,
    },
  ],
};

const MainRoutes = [
  {
    name: "style-guide",
    path: "/style-guide",
    element: (
      <>
        <StyleGuidePage />
      </>
    ),
  },
  {
    name: "Home",
    path: "/",
    element: (
      <>
        <HomePage />
      </>
    ),
  },

  {
    name: "getstarted",
    path: "get-started",
    element: (
      <>
        <GetStartedPage />
      </>
    ),
  },
  {
    name: "choose-plan",
    path: "choose-plan",
    element: (
      <>
        <ChoosePlanPage />
      </>
    ),
  },
  {
    name: "hdfc_failure",
    path: "/payment_failure",
    element: (
      <>
        <HdfcSuccess type={"error"} />
      </>
    ),
  },
  {
    name: "hdfc",
    path: "/payment_success",
    element: (
      <>
        <HdfcSuccess type={"success"} />
      </>
    ),
  },
  {
    name: "test-payment",
    path: "/payment/:payment_id/:country_id/:price",
    element: (
      <>
        <TestPayment />
      </>
    ),
  },
  {
    name: "view-content",
    path: "view-content/:secret_token",
    element: <ViewContent />,
  },

  {
    name: "profile",
    path: "profile/:secret_token",
    element: <ShareUserProfile />,
    Children: [
      {
        name: "overView",
        path: "",
        element: <StarredImages />,
      },
    ],
  },
  {
    name: "albumshare",
    path: "albumshare/:albumId/:secret_token",
    element: <ShareAlbum />,
  },
  {
    ...DashobardRoute,
  },
  {
    name: "contact",
    path: "contact",
    element: <ContactPage />,
  },
  {
    name: "familyTreeForm",
    path: "family-tree-form",
    element: <FamilyTreeForm />,
  },

  {
    name: "privacy-policy",
    path: "privacy-policy",
    element: <LegalPages />,
  },

  {
    name: "InformationAndGuidelines",
    path: "terms-of-service-and-guidelines",
    element: <LegalPages />,
  },

  {
    name: "FAQ",
    path: "faq",
    element: <LegalPages />,
  },

  {
    name: "CancellationAndRefundPolicy",
    path: "cancellation-and-refund-policy",
    element: <LegalPages />,
  },
  {
    name: "quick-demo",
    path: "quick-demo",
    element: <DemoPage />,
  },
  {
    name: "demo",
    path: "quick-demo/:demoslug",
    element: <DemoVideo />,
  },
  {
    name: "404",
    path: "*",
    element: <ErrorPage />,
  },
];

export default MainRoutes;
