import React from 'react';
import Assets from '../Layouts/Assets';
import Style from './FPALoader.module.scss'

const FPALoader = () => {
  return (
    <div className={Style.loader}>
      <figure>
        <img src={Assets.logo_icon} alt='' className={Style.logo_icon} />
        <div className={Style.logo_dots}>
          <img src={Assets.logo_dot_yellow} alt='' />
          <img src={Assets.logo_dot_green} alt='' />
          <img src={Assets.logo_dot_blue} alt='' />
        </div>
      </figure>
    </div>
  )
}

export default FPALoader;