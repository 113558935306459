import React from "react";
import { useSelector } from "react-redux";
import CommonModal from "../../../../Modals/commonModal";
import FPALoader from "../../../../FPALoader";
import Style from "./UploaderClosingWarning.module.scss"

const UploderClosinWarning = ({
  uploadAlert,
  setUploadAlert,
  handleQuitUploading,
}) => {
  const { status } = useSelector((state) => state.gallery);
  return (
    <CommonModal
      showModal={uploadAlert}
      hide={() => setUploadAlert()}
      className={Style.delete_this_photo_modal}
      title={""}
      content={
        < div className={Style.warningModal}>
          <h3 className={Style.delete_title}>Upload in Progress</h3>
          <p className={Style.delete_content}>
            Image uploading is still in progress. Closing this window will
            cancel the upload. Are you sure you want to proceed?
          </p>
          <div className={Style.delete_btn_wrap}>
            <button
              className="btn-danger"
              onClick={(e) => handleQuitUploading()}
            >
              Yes
            </button>
            <button
              className="btn-outline-gray"
              onClick={() => setUploadAlert()}
            >
              No
            </button>
          </div>
        </div>
      }
    />
  );
};

export default UploderClosinWarning;
