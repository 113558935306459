import React, { useEffect, useState } from "react";
import Icons from "../Layouts/Icons";
import Style from "./DarkThemeToggler.module.scss";
import { useTranslation } from "react-i18next";
import { usePreference } from "../../Logic/usePreference";
import { useSelector } from "react-redux";

const DarkThemeToggler = ({
  theme,
  toggleTheme,
  className,
  text,
  isDarkMode,
}) => {
  const { i18n, t } = useTranslation(["common"]);
  const { themes,setTheme } = usePreference();
const {themeMode} = useSelector((state)=> state.userpreference)
  const defaultTheme = window.localStorage.getItem("theme");

  return (
    <button
      className={`${
        Style.darkmode_toggler
      } ${className} ${"btn_theme_toggle anim dark-toggler-anim btn_darkmode_theme_toggle"}`}
      onClick={() => {
        toggleTheme()}}
    >
      {defaultTheme == "dark" ? (
        <div className={`${Style.light_mode} theme-mode theme-light`}>
          <Icons icon={"icon-sun"} size={30} />
          {text !== "none" ? <span>{t("light")}</span> : ""}
        </div>
      ) : (
        <div className={`${Style.dark_mode} theme-mode theme-dark`}>
          {text !== "none" ? <span>{t("dark")}</span> : ""}
          <Icons icon={"icon-moon"} size={26} />
        </div>
      )}
    </button>
  );
};

export default DarkThemeToggler;
