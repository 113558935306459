import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDarkmode from "../../../utils/utilityHooks/useDarkmode";
import { selectVideos } from "../../../store/slices/Videos/VideoLinkSlice";


export const useVideoItem = () => {
    const dispatch = useDispatch()
    const { videoActions } = useSelector((state) => state?.videos);
    const [isLoaded, setIsLoaded] = useState(false)
    const { theme } = useDarkmode();
    let tempCard =
        theme !== "dark"
            ? "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3crect id='Rectangle_22157' data-name='Rectangle 22157' width='12' height='12' fill='%23e8e8e8'/%3e%3c/svg%3e"
            : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3crect id='Rectangle_22157' data-name='Rectangle 22157' width='12' height='12' fill='%233a3a3a'/%3e%3c/svg%3e";



    const handleLoad = (e) => {
        setIsLoaded(true)
    }

    const handleSelectVideo = (id ,type) => {
        if (videoActions?.isPreSelect) {
            dispatch(selectVideos(id))
        }

    }


    return {
        tempCard,
        isLoaded,
        videoActions,
        handleLoad,
        handleSelectVideo
    }
}