import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAxiosInstance } from "../../../api";
import { toast } from "react-toastify";

export const useShareAlbum = () => {
  let { secret_token, albumId } = useParams();
  const [data, setData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (secret_token) {
      getData(secret_token, albumId);
    }
  }, []);

  const getData = async (token, albumId) => {
    const api = await getAxiosInstance();

    try {
      setIsLoading(true);
      const res = await api.get(`/share-album?token=${token}`, {
        params: { album_id: albumId },
      });
      if (res.status == 200) {
        setData(res?.data?.result);
      }
    } catch (err) {
      setIsInvalid(true);
      toast.warning("token invalid!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isInvalid,
    isLoading,
  };
};
