import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeImageFavorite } from "../../../../../store/slices/favoriteSlice";
import { RemoveImages } from "../../../../../store/slices/GalleryImagesSlice";
import { disableImageSelection } from "../../../../../store/slices/GalleryTabSlice";
import { getStaredList } from "../../../../../store/slices/ProfileGallery/profileGallerySlice";
import {
  getProfileDetails,
  updatePlanPercentage,
} from "../../../../../store/slices/userprofileSlice";

export const useDeleteImageModal = ({ setDeleteModal }) => {
  const dispatch = useDispatch();
  const selectedImage = useSelector(
    (state) => state.galleryTab.ImageAllTabSelection
  );
  const Images = useSelector((state) => state.galleryAllTab);
  const { galleryAllTab } = useSelector((state) => state);
  const { userdata } = useSelector((store) => store.userprofile);


  const handleDelete = async () => {
    let image_id = selectedImage?.selectedImage;
    let AllImages = Images?.resultData?.data?.filter((item) =>
      image_id.includes(item.id)
    );
    let allFavImages = AllImages?.filter((item) => item.favourite == 1).map(
      (item) => item.id
    );

    const resultAction = await dispatch(
      RemoveImages({ image_id: image_id, data: AllImages })
    );
    if (RemoveImages.fulfilled.match(resultAction)) {
      if (allFavImages?.length > 0) {
        dispatch(removeImageFavorite({ image_id: allFavImages }));
      }
      dispatch(disableImageSelection({ tab: "" }));
      setDeleteModal({ popup: false });
      dispatch(getStaredList());
    }
    await dispatch(getProfileDetails());
  };

  return {
    selectedImage,
    handleDelete,
    status: Images?.isDeleting,
  };
};
