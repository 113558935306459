import Style from "../UserProfileOverview.module.scss";
import Icons from "../../../Layouts/Icons";

const ProfileGalleryActions = ({
  data,
  options,
  closeReArrange,
  opemReArrange,
  openStaredImages,
}) => {
  return (
    <>
      <div className={Style.Stared_actions}>
        {options?.isRearrange ? (
          <button
            className={`${Style.edit_btn} btn btn-sm btn-border btn-border-grey`}
            onClick={() => {
              closeReArrange();
            }}
          >
            Close
          </button>
        ) : (
          <>
            {data?.length > 0 ? (
              <button
                className={`${Style.edit_btn} btn btn-sm btn-border btn-border-grey`}
                onClick={() => {
                  opemReArrange();
                }}
              >
                <Icons icon={"icon-grid"} size={16} />
                {"Re-arrange"}
              </button>
            ) : (
              ""
            )}

            <button
              className={`${Style.edit_btn} btn btn-sm btn-border btn-border-grey`}
              onClick={() => openStaredImages()}
            >
              <Icons icon={"edit"} size={16} />
              Update
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileGalleryActions;
