import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay, Lazy } from "swiper";
import Style from "./SlideShowFullView.module.scss";
import Icons from "../../Layouts/Icons";
import { useEffect } from "react";
import useAudioPlayer from "../../../Logic/useAudioPlayer";
import { useSingleKey } from "../../../utils/KeyBoardShortCuts";
import { ControlTab } from "./ControlTab";
import { useFullScreen } from "./useFullScreen";
import { useControls } from "./useControls";

const SlideShowFullView1 = ({
  data,
  images,
  close,
  play,
  setPlay,
  slideIndex,
  mute,
  setMute,
  setSlideIndex,
}) => {
  //FIXME quickslideshow slider-delay issue pending
  const swiperRef = useRef(null);
  const { fullScreenRef, isFullscreen, fullScreenMode, controlsVisible, setControlsHoverVisible } =
    useFullScreen();

  const {
    currentIndex,
    showPlayPause,
    setShowPlayPause,
    slideChangeHandler,
    playToggle,
    pauseToggle,
    previousHandler,
    nextHandler,
    handleInitialization,
    handleMute,
  } = useControls({ swiperRef, play, setPlay, mute, setMute, setSlideIndex });

  useEffect(() => {
    if (!isFullscreen && fullScreenMode) {
      close();
    }
  }, [isFullscreen]);

  useSingleKey("ArrowLeft", previousHandler);
  useSingleKey("ArrowRight", nextHandler);


  return ReactDOM.createPortal(
    <div className={Style.slideshow_fullscreen} ref={fullScreenRef}>
      <div className={Style.back_btn} onClick={close}>
        <Icons icon={"back-arrow"} size={20} color="#ffff" />
      </div>
      <Swiper
        initialSlide={slideIndex}
        ref={swiperRef}
        loop={true}
        slidesPerView={1}
        spaceBetween={10}
        navigation={true}
        speed={2000}
        modules={[FreeMode, Navigation, Thumbs, Autoplay, Lazy]}
        lazy={true}
        className="mySwiper2"
        onSlideChange={slideChangeHandler}
        onInit={(e) => handleInitialization(e)}
        allowTouchMove={false}
      >
        {images?.map((imgItem, index) => {
          return (
            <SwiperSlide
              className={currentIndex == index ? (data?.effect!=null ?data?.effect: 'fade-effect' ) : "non"}
              key={imgItem.id}
            >
              <figure className={Style.slideshow_fullscreen_image}>
                <img
                  data-src={imgItem?.src?.original}
                  className="swiper-lazy"
                  alt=""
                />
              </figure>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <ControlTab
        onPreviousHandler={previousHandler}
        onNextHandler={nextHandler}
        onPauseToggle={pauseToggle}
        onPlayToggle={playToggle}
        onHandleMute={handleMute}
        onClose={close}
        isVisible={controlsVisible}
        setVisible={setControlsHoverVisible}
        showPlayPause={showPlayPause}
        play={play}
        mute={mute}
        // isMute={mute}
      />
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default SlideShowFullView1;
