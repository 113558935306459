import { Slider } from "antd";
import React, { useRef, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/Crop/imageCrop";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import CommonModal from "../../Modals/commonModal";
import Assets from "../../../components/Layouts/Assets";
import Style from "./profilePictureModal.module.scss";
import { useEffect } from "react";

const ProfilePictureModal = ({
  imageSrc,
  open,
  close,
  handleDragOver,
  handleDrop,
  handleDragOut,
  saveImage,
  loading,
}) => {
  const [profileCrop, setProfileCrop] = useState({ x: 0, y: 0 });
  const [profileZoom, setProfileZoom] = useState(1);
  const [blob, setBlob] = useState(null);
  const inputFileForm = useRef(null);

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    // getBlob(croppedImage)
    setBlob(croppedImage);
  };

  useEffect(() => {
    if (!open) {
      setProfileZoom(1);
    }
  }, [open]);

  return (
    <>
      <CommonModal
        showModal={open}
        hide={close}
        className={`${Style.ProfileImageCrop__Modal}`}
        title={""}
        content={
          <>
            <div className={Style.file_upload_modal_form}>
              <h3 className={Style.edit_profile_info_title}>
                {/* Upload profile picture */}
              </h3>

              <div className={Style.ProfileImageCrop__contents}>
                {imageSrc ? (
                  <>
                    <figure className={Style.ProfileImageCrop__previewImage}>
                      <Cropper
                        image={imageSrc}
                        crop={profileCrop}
                        zoom={profileZoom}
                        aspect={1 / 1}
                        cropShape="round"
                        onCropChange={setProfileCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setProfileZoom}
                      />
                    </figure>

                    <div className={Style.ProfileImageCrop__controller}>
                      <Slider
                        defaultValue={profileZoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e) => {
                          setProfileZoom(e);
                        }}
                        className={Style.zoom_slider}
                      />
                    </div>
                  </>
                ) : (
                  <div className={Style.Profile_loader}>
                    <InfiniteDotLoader />
                  </div>
                  // <div
                  //   className={Style.ProfileImageCrop__uploadBox}
                  //   onDragOver={handleDragOver}
                  //   onDrop={handleDrop}
                  //   onDragLeave={handleDragOut}
                  // >
                  //   <label htmlFor="uploadImg">
                  //     <figure>
                  //       <img src={Assets.file_upload_image} alt="" />
                  //     </figure>
                  //     <p>
                  //       Drag and drop <br /> an image, <span>or Browse</span>
                  //     </p>
                  //     <form ref={inputFileForm}>
                  //       <input
                  //         type="file"
                  //         className="d-none"
                  //         name="file"
                  //         id="uploadImg"
                  //       />
                  //     </form>
                  //   </label>
                  // </div>
                )}
                <p className={Style.ProfileImageCrop__uploadFileLimit}>
                  {/* {limitations} */}
                </p>
              </div>

              {imageSrc ? (
                <div className={Style.ProfileImageCrop__btnWrap}>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      close();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      saveImage("tree", blob);
                    }}
                    className="btn btn-tertiary"
                  >
                    {!loading ? "Upload" : <InfiniteDotLoader />}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default ProfilePictureModal;
