import React, { useEffect } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import Styles from "./commonModal.module.scss";
import ReactDOM from "react-dom";
import { useState } from "react";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";

const CommonModal = ({ showModal, hide, title, content, className }) => {
  useEffect(() => {
    showModal &&
      (document.querySelector("body").style.cssText =
        "overflow: initial !important");
  }, [showModal]);

  const [isModalVisible, setIsModalVisible] = useState();

  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(isModalVisible === true);
  }, [isModalVisible === true]);

  return ReactDOM.createPortal(
    <>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={hide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={className}
        onExit={() => setIsModalVisible(false)}
        onEnter={() => setIsModalVisible(true)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
      </Modal>
    </>,
    document.getElementById("slideshow-root")
  );
};

export default CommonModal;
