import React from "react";
import useDarkmode from "../../../utils/utilityHooks/useDarkmode";
import PreLogin from "../../../components/Layouts/PreLogin";
import DarkThemeToggler from "../../DarkThemeToggler";
import { useShareAlbum } from "./useShareAlbum";
import AlbumImageContainer from "./AlbumImageContainer";

const ShareAlbum = () => {
  const { theme, themeToggler } = useDarkmode();
  const { data, isInvalid, isLoading } = useShareAlbum();
  return (
    <div>
      <PreLogin hasFooter={true}>
        <div className="container">
          <AlbumImageContainer
            data={data}
            isInvalid={isInvalid}
            isLoading={isLoading}
          />
        </div>
      </PreLogin>
      <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
    </div>
  );
};

export default ShareAlbum;
