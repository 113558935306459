import { useEffect, useState } from "react";
import useEventListener from "../utilityHooks/useEventListener";

export default function useOnlineStatus() {
  const [online, setOnline] = useState(navigator.onLine);

  useEventListener("online", () => {
    setOnline("online");
  });
  useEventListener("offline", () => {
    setOnline("offline");
  });

  useEffect(() => {
    setTimeout(() => {
      setOnline("");
    }, 1000);
  }, [online]);

  return online;
}
