import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLanguages } from "../store/slices/HomeSlice";
import useAuthChecker from "./useAuthChecker";
import Assets from "../components/Layouts/Assets";
import { useTranslation } from "react-i18next";
import {
  getCurrentPreferenceStatus,
  updatePreferenceStatus,
} from "../store/slices/preferenceSlice";

export const useLanguage = () => {
  const dispatch = useDispatch();
  const { isAuthCompleted } = useAuthChecker();
  const [language, setLanguage] = useState("English");
  const [flag, setFlag] = useState(Assets.en_flag);
  const { i18n, t } = useTranslation(["common"]);

  const { availableLanguage } = useSelector((state) => state.home);

  const languageSwitch = (language, flag, code, lang) => {
    if (isAuthCompleted === true) {
      i18n.changeLanguage(code);
      dispatch(
        updatePreferenceStatus({
          action: "language_id",
          value: lang?.id,
        })
      );
    }
    setLanguage(language);
    setFlag(flag);
    localStorage.setItem("currentLanguage", language);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  useEffect(() => {
    if (availableLanguage.length === 0) {
      dispatch(getLanguages())
        .unwrap()
        .then(function (data) {});
    }

    if (isAuthCompleted === true) {
      dispatch(getCurrentPreferenceStatus())
        .unwrap()
        .then(function (data) {
          setLanguage(data?.data?.result?.language?.name);
          i18n.changeLanguage(data?.data?.result?.language.locale);
        });
    }
  }, []);

  return {
    availableLanguage,
    isAuthCompleted,
    language,
    setLanguage,
    languageSwitch,
    flag,
    setFlag,
  };
};
