import Style from "./Dashboard.module.scss";
import Icons from "../../components/Layouts/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FamilyUsersPrice from "../FamilyUsersPrice";
import { Left_Arrow } from "../../assets/svg_components/gallery";
import useRouteUtils from "./useRouteUtils";
import Img from "react-cool-img";
import Assets from "../Layouts/Assets";

const Sidebar = ({ collapseMenu, setCollapseMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { default_view } = useSelector((state) => state.userpreference);

  const handleSelectDashboardPage = (data) => {
    if (default_view == "album" && data?.path == "") {
      navigate(`album`);
    } else {
      navigate(`${data?.path}`);
    }
  };

  const { checkIfActiveRoute } = useRouteUtils();

  let list = [
    {
      name: "My Gallery",
      iconName: "icon-image",
      path: "",
      isActive: checkIfActiveRoute("/dashboard", true),
    },
    {
      name: "Family Tree",
      iconName: "icon-people",
      path: "family-tree",
      isActive: checkIfActiveRoute("/family-tree"),
    },
    {
      name: "My Video Links",
      iconName: "video-pay",
      path: "my-videos-links",
      isActive: checkIfActiveRoute("/my-videos-links"),
    },
    {
      name: "Preferences",
      iconName: "icon-settings",
      path: "preferences",
      isActive: checkIfActiveRoute("/preferences"),
    },
  ];

  return (
    <div
      className={`${Style.dashboard_sidebar} ${
        collapseMenu ? Style.sidebar_collapse : ""
      }`}
    >
      <figure className={Style.collapse_holder}>
        <Left_Arrow Style={Style} />
        <button
          className={`${Style.collapse_holder_btn} ${
            !collapseMenu ? "" : Style.collapse_menu_closed
          }`}
          onClick={() => {
            !collapseMenu ? setCollapseMenu(true) : setCollapseMenu(false);
          }}
        >
          <Icons
            icon={"collapse-button"}
            size={42}
            className={!collapseMenu ? "" : Style.collapse_menu_closed_icon}
          />
        </button>
      </figure>
      <div className={`${Style.dashboard_sidebar_menu} sidebar-menu`}>
        <div className={Style.dashboard_sidebar_menu_top}>
          <ul className={Style.dashboard_sidebar_links}>
            <>
              {list.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${Style.dashboard_tab_active}  ${
                      item?.isActive && "dashboard-tab-active"
                    }`}
                    onClick={() => handleSelectDashboardPage(item)}
                  >
                    <div className={Style.sidebaritem}>
                      <Icons icon={item?.iconName} size={17} />
                      <span>{item.name}</span>
                      {item.path == "family-tree" && (
                        <img
                          className={Style.tree_beta}
                          src={Assets.betaIcon}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </>
          </ul>
        </div>

        <FamilyUsersPrice collapseMenu={collapseMenu} />
      </div>
    </div>
  );
};

export default Sidebar;
