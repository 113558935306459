import React from "react";
import { useHeaderFilter } from "./useHeaderFilter";
import Style from "../VideoGallery.module.scss";
import { SelectAllBtn } from "./SelectAllBtn";
import Icons from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";
import { useState } from "react";
import { SelectBtn } from "./SelectBtn";

const HeaderFilter = () => {
  const windowDimensions = useDimensions();
  const {
    videoActions,
    selectActive,
    dropDownSelect,
    handleSort,
    handleOpenVideoModal,
    handlePreSelect,
    handleClearState,
    handleSelectAll,
    handleDeleteItems,
  } = useHeaderFilter();
  return (
    <>
      {videoActions?.isPreSelect ? (
        <>
          <button
            disabled={videoActions?.selected_Ids?.length === 0}
            className={`a04 ${Style.add_btn} ${
              Style.dashboard_btn
            } ${"btn btn-border-grey"}`}
            onClick={() => handleDeleteItems()}
          >
            Delete
          </button>
          <SelectAllBtn handleSelectAll={handleSelectAll} />
          <button
            className={`${Style.close_select}`}
            onClick={() => handleClearState()}
          >
            <Icons color="currentColor" size={16} icon={"icon-close"} />
          </button>
        </>
      ) : (
        <>
          <button
            className={`a04 ${Style.add_btn} ${
              Style.dashboard_btn
            } ${"btn btn-outline-light"}`}
            onClick={() => handleOpenVideoModal()}
          >
            <Icons
              color="currentColor"
              className={"me-2"}
              icon={"add"}
              size={12}
            />
            {windowDimensions.width >= 1200 && "Upload video links"}
          </button>
          <div className="dropdown">
            <button
              className={`btn btn-border-grey ${Style.Dropdown_toggle_btn}`}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {dropDownSelect}
              <Icons
                icon={"icon-dropdown-arrow"}
                color={"currentColor"}
                size={10}
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {sortData?.map((item, index) => {
                return <li onClick={() => handleSort(item)}>{item.label}</li>;
              })}
            </ul>
          </div>
          <SelectBtn handlePreSelect={handlePreSelect} />
        </>
      )}
    </>
  );
};

export default HeaderFilter;

let sortData = [
  {
    label: "Latest",
    slug: "desc",
  },
  {
    label: "Oldest",
    slug: "asc",
  },
];
