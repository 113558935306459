import axios from "axios";


const QA_URL = process.env.REACT_APP_APIURL_QA
const TEST_URL = process.env.REACT_APP_APIURL;
const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

let user = "";
let token = "";

let lan = localStorage.getItem("i18nextLng");
let lang = lan ? lan : "en";

let MainURL = process.env.REACT_APP_DEV_ENV == "PRODUTION" ? BASE_URL : process.env.REACT_APP_DEV_ENV == "DEMO" ? TEST_URL : QA_URL


export const getAxiosInstance = async () => {
  try {
    token = window.localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: MainURL,
      params: { lang },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
