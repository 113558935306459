import React, { useEffect } from "react";
import Style from "./ForgotPasswordModal.module.scss";
import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLogin } from "../../../Logic/useLogin";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeScreen } from "../../../store/slices/authSlice";
import useAuthChecker from "../../../Logic/useAuthChecker";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";

const VerifyEmail = ({ status, onError, forgotPasswordHandler, resetForm }) => {
  const { currentAuthData, existingUser } = useSelector((state) => state.auth);
  const [initialData, setInitialData] = useState({
    mail: existingUser[0]?.mail ? existingUser[0]?.mail : "",
  });
  const { i18n, t } = useTranslation(["authentication"]);
  const { isAuthCompleted } = useAuthChecker();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentAuthData?.mail) {
      setInitialData({
        mail: currentAuthData?.mail,
      });
      setTimeout(() => {
        document.querySelectorAll(".modal input")[0].focus();
      }, 500);
    }
  }, [currentAuthData]);

  const validationSchema = Yup.object().shape({
    mail: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
  });

  return (
    <Formik
      initialValues={initialData}
      onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
        forgotPasswordHandler(values, {
          resetForm,
          setErrors,
        });
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
      }) => {
        let hasErrors = Object.keys(errors).length == 0;

        return (
          <Form>
            <div
              className={`${Style.email_with_change} ${
                errors.mail && touched.email ? "form-group error" : "form-group"
              }`}
            >
              <label htmlFor="fp_input_email">{`${t(
                "register_email"
              )} *`}</label>

              <div className="input-holder">
                {/* <Field
                  type="email"
                  name="mail"
                  id="mail"
                  className="form-control"
                  placeholder="Enter your mail address"
                /> */}

                <input
                  type="email"
                  value={values?.mail}
                  // value={values?.mail}
                  onBlur={handleBlur}
                  name="mail"
                  onChange={(e) => {
                    setFieldValue("mail", e.target.value);
                  }}
                  onClick={(e) => {
                    e.target.value = e.target.value;
                  }}
                  placeholder={t("register_email_placeholder")}
                  id="mail"
                  className="form-control"
                />

                <ErrorMessage name="mail" className="field_error_message">
                  {(msg) => (
                    <div
                      style={{
                        color: "#FF6261",
                        fontSize: "13px",
                        marginTop: "5px",
                      }}
                      className="error-msg"
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </div>

            {onError ? (
              <>
                <div className={Style.show_otp_msg_response}>
                  <span className={Style.errorMessage}>
                    <p>{onError}</p>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            <button
              disabled={!hasErrors}
              onClick={handleSubmit}
              type="submit"
              className={`${Style.signin_button} ${"btn btn-secondary w-100"} `}
            >
              {status == "loading" ? <InfiniteDotLoader /> : t("sign_in_btn")}
            </button>
            <div className={Style.back_btn_wrap}>
              <span>Back to sign in?</span>
              <button
                onClick={() => {
                  if (isAuthCompleted === false) {
                    dispatch(changeScreen("login"));
                  }
                }}
                className={Style.back_btn}
              >
                Sign In
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VerifyEmail;
