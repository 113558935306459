import { TellUsForm } from "../TellUsForm";
import { TellUsTabs } from "../TellUsTabs";
import { useState } from "react";
import Style from "./TellUsAboutYourFamily.scss";

export const TellUsAbout = () => {
  const [type, setType] = useState("you");

  return (
    <div className={Style.tellusabout}>
      <TellUsTabs setType={setType} />
      <TellUsForm type={type} />
    </div>
  );
};
