import React from "react";
import Style from "./StarredGalleryGrid.module.scss";
import { useRef, useCallback } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import Icons from "../Layouts/Icons";
import useGalleryImage from "../../Logic/useGalleryImage";

const StarredImageItem = ({
  image,
}) => {
  const someRef = useRef(null);

  const { toBeDragged, setToBeDragged, handleMouseDown, handleMouseUp } =
    useGalleryImage();
  return (
    <>
      <div
        className={Style.starred_gallery_grid_wrap}
        style={{
          opacity: 1,
          position: "relative",
        }}
        ref={someRef}
      >
        <figure className={`${Style.starred_gallery_grid_image} `}>
          <img
            ref={someRef}
            src={image?.src?.encoded}
            alt=""
            onMouseDown={() => handleMouseDown()}
            onMouseUp={handleMouseUp}
          />
        </figure>
      </div>
    </>
  );
};

export default StarredImageItem;
