import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  phoneUpdateformSubmittedDetails: [],
  updatePhoneData:[]
};


export const phoneUpdateForm = createAsyncThunk(
  "phoneupdate/phoneUpdateForm",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const { action, value } = obj;

      const packet = new FormData();

      packet.append("number", obj.phone_number);
      packet.append("password", obj.password);
      packet.append("dial_code", obj.country_dial_code);
      packet.append("country_code", obj.country_code);



      const response = await api.post(`send-phone-otp`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const phoneUpdateSlice = createSlice({
  name: "phoneupdate",
  initialState,
  reducers: {
    clearFormResult: (state) => {
      state.phoneUpdateformSubmittedDetails = [];
    },
    updatePhoneData: (state,action) => {
      state.updatePhoneData = action.payload
    }
  },
  extraReducers: {
    [phoneUpdateForm.pending]: (state) => {
      state.isLoading = true;
    },
    [phoneUpdateForm.fulfilled]: (state, action) => {
      state.phoneUpdateformSubmittedDetails = action.payload.data.result == null ? [] : action.payload.data.result;
      state.isLoading = false;
    },
    [phoneUpdateForm.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});


export const { clearFormResult , updatePhoneData} = phoneUpdateSlice.actions;

export default phoneUpdateSlice.reducer;

