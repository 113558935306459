import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Style from "./FullscreenPreview.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useSingleKey } from "../../../utils/KeyBoardShortCuts/index";
import { WithContext as ReactTags } from "react-tag-input";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  Lazy,
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  EffectCoverflow,
  EffectFade,
} from "swiper";
import Icons from "../../Layouts/Icons";
import FullViewImage from "../FullViewImage";
import { Offcanvas } from "react-bootstrap";
import { useDimensions } from "../../../Logic/Dimensions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Zoom from "../Zoom";
import useFullscreenPreview from "../../../Logic/useFullscreenPreview";
import ShareModal from "../../ShareModal";
import { useSocial } from "../../../Logic/useSocial";
import sizeConverter from "../../../utils/utilityHooks/sizeConverter";
import Details from "./Details";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const FullscreenPreview = ({
  setShowFullView,
  showFullView,
  data,
  pathFrom,
  ImageEditSection,
  openSlideShow,
  closePreview,
}) => {
  const navigate = useNavigate();
  const windowDimensions = useDimensions();
  const [initialIndex, setInitialIndex] = useState(0);
  const { currentPage } = useSelector((state) => state.gallery);
  const {
    slideCount,
    setSlideCount,
    fullViewThumbs,
    setFullViewThumbs,
    fullView,
    setFullView,
    fullViewThumbIndex,
    setFullViewThumbIndex,
    isZoom,
    setIsZoom,
    tags,
    setTags,
    zoomCount,
    setZoomCount,
    deletePhotoModal,
    setDeletePhotoModal,
    itemToDelete,
    setItemToDelete,
    zoom,
    setZoom,
    navAreaLeftHover,
    setNavAreaLeftHover,
    navAreaRightHover,
    setNavAreaRightHover,
    show,
    setShow,
    menuVisible,
    setMenuVisible,
    swiperRef,
    zoomRef,
    handleClick,
    handleDelete,
    handleAddition,
    handleZoom,
    handleClickSlideShow,
    slideChangeHandler,
    previousHandler,
    nextHandler,
    closeButtonHandle,
  } = useFullscreenPreview({
    setShowFullView,
    showFullView,
    data,
  });

  const { fetchSocialDetails, secretToken, openShare, setOpenShare } =
    useSocial();

  const handleEscape = () => {
    setIsZoom(false);
    setFullViewThumbs(false);
    setShowFullView({});
  };
  const [navHover, setNavHover] = useState(false);

  useEffect(() => {
    setInitialIndex(data.findIndex((item) => item.id == showFullView.imageId));
  }, [showFullView]);

  let mouseTimer = null;
  const disableMenu = () => {
    mouseTimer = null;
    navHover ? setMenuVisible(true) : setMenuVisible(false);
  };

  window.addEventListener("mousemove", () => {
    mouseTimer && window.clearTimeout(mouseTimer);
    !menuVisible && setMenuVisible(true);
    mouseTimer = window.setTimeout(disableMenu, 3000);
  });
  window.addEventListener("click", () => {
    mouseTimer && window.clearTimeout(mouseTimer);
    !menuVisible && setMenuVisible(true);
    mouseTimer = window.setTimeout(disableMenu, 3000);
  });

  const handleInfoClose = () => setShow(false);
  const handleInfoShow = () => setShow(true);

  const RemoveComponent = ({ className, onRemove }) => {
    return (
      <button onClick={onRemove} className={className}>
        <Icons icon={"icon-close"} size={8} color="#f00" />
      </button>
    );
  };

  useSingleKey("ArrowLeft", previousHandler);
  useSingleKey("ArrowRight", nextHandler);
  useSingleKey("Escape", handleEscape);

  const handleOpenDeleteModal = (id) => {
    setItemToDelete(id);
    setDeletePhotoModal(!deletePhotoModal);
  };

  const handleCloseBtn = () => {
    if (pathFrom == "search") {
      navigate(-1);
    } else {
      closeButtonHandle();
    }
  };

  const handleOpenShareModal = () => {
    setOpenShare(!openShare);
    fetchSocialDetails(data[fullViewThumbIndex]?.id);
  };

  const editMode = () => {
    let isEdit = false;
    if (currentPage == "all") {
      isEdit = true;
    } else if (currentPage == "album") {
      isEdit = true;
    } else {
      isEdit = false;
    }
    return isEdit;
  };

  //FIXME using old code for now
  return ReactDOM.createPortal(
    <>
      <div
        className={`${Style.album_full_view} ${
          isZoom && menuVisible ? "menu-visible" : ""
        } ${isZoom ? Style.zoom_view : ""} 
          ${
            navAreaLeftHover && swiperRef?.current?.swiper?.activeIndex !== 0
              ? Style.nav_left_active
              : ""
          } 
          ${
            navAreaRightHover &&
            swiperRef?.current?.swiper?.activeIndex !==
              swiperRef?.current?.swiper?.slides?.length - 1
              ? Style.nav_right_active
              : ""
          }`}
        ref={zoomRef}
      >
        {!isZoom && (
          <>
            <div
              className={Style.nav_area_left}
              onClick={previousHandler}
              onMouseEnter={() => setNavAreaLeftHover(true)}
              onMouseLeave={() => setNavAreaLeftHover(false)}
            ></div>
            <div
              className={Style.nav_area_right}
              onClick={nextHandler}
              onMouseEnter={() => setNavAreaRightHover(true)}
              onMouseLeave={() => setNavAreaRightHover(false)}
            ></div>
            <button
              className={`${Style.control_btn} swiper-button-prev`}
              onClick={previousHandler}
              onMouseEnter={() => setNavAreaLeftHover(true)}
              onMouseLeave={() => setNavAreaLeftHover(false)}
            >
              <Icons icon={"prev-arrow"} size={15} />
            </button>
            <button
              className={`${Style.control_btn} swiper-button-next`}
              onClick={nextHandler}
              onMouseEnter={() => setNavAreaRightHover(true)}
              onMouseLeave={() => setNavAreaRightHover(false)}
            >
              <Icons icon={"next-arrow"} size={15} />
            </button>
          </>
        )}
        <div
          className={`${Style.album_full_view_header} ${
            menuVisible ? Style.menu_show : ""
          }`}
        >
          <div className={Style.album_full_view_header_leftnav}>
            {!isZoom ? (
              <button
                className={Style.album_full_view_header_btn}
                onClick={closePreview}
                onMouseEnter={() => setNavHover(true)}
                onMouseLeave={() => setNavHover(false)}
              >
                <Icons
                  icon={"back-arrow"}
                  size={windowDimensions.width >= 1279 ? 20 : 14}
                />
              </button>
            ) : (
              ""
            )}
          </div>
          <div className={Style.album_full_view_header_rightnav}>
            {windowDimensions.width < 992 ? (
              <>
                <button className={Style.album_full_view_header_btn_mob}>
                  <Link
                    to={`/dashboard/image-edit/${pathFrom}/${ImageEditSection}/${data[fullViewThumbIndex]?.id}`}
                  >
                    <Icons icon={"edit"} size={17} />
                  </Link>
                </button>
                <button
                  className={`${Style.album_full_view_header_btn_mob} ${Style.tray_actions_btn_no_like}`}
                >
                  <Icons icon={"icon-love"} size={17} />
                </button>
                {data?.length > 1 ? (
                  <button className={Style.album_full_view_header_btn_mob}>
                    <Icons icon={"slideshow"} size={17} />
                  </button>
                ) : (
                  ""
                )}

                <button
                  className={Style.album_full_view_header_btn_mob}
                  onClick={() => handleInfoShow()}
                >
                  <Icons icon={"info"} size={18} />
                </button>
              </>
            ) : !isZoom ? (
              <>
                <button
                  className={`btn btn-link ${Style.album_full_view_header_btn}`}
                  onMouseEnter={() => {
                    setNavAreaRightHover(true);
                    setNavHover(true);
                  }}
                  onMouseLeave={() => {
                    setNavAreaRightHover(false);
                    setNavHover(false);
                  }}
                  onClick={() => handleOpenShareModal()}
                >
                  <Icons icon={"share2"} size={17} /> Share
                </button>
                {editMode() && pathFrom !== "searchlist" ? (
                  <button
                    className={`btn btn-link ${Style.album_full_view_header_btn}`}
                    onMouseEnter={() => {
                      setNavAreaRightHover(true);
                      setNavHover(true);
                    }}
                    onMouseLeave={() => {
                      setNavAreaRightHover(false);
                      setNavHover(false);
                    }}
                  >
                    <Link
                      to={`/dashboard/image-edit/${pathFrom}/${ImageEditSection}/${data[fullViewThumbIndex]?.id}`}
                    >
                      <Icons icon={"edit"} size={18} /> Edit
                    </Link>
                  </button>
                ) : (
                  ""
                )}
                {data?.length > 1 ? (
                  <button
                    className={`btn btn-link ${Style.album_full_view_header_btn}`}
                    onMouseEnter={() => {
                      setNavAreaRightHover(true);
                      setNavHover(true);
                    }}
                    onMouseLeave={() => {
                      setNavAreaRightHover(false);
                      setNavHover(false);
                    }}
                  >
                    <div onClick={() => openSlideShow()}>
                      <Icons icon={"slideshow"} size={20} /> Slideshow
                    </div>
                  </button>
                ) : (
                  ""
                )}
                <button
                  className={`btn btn-link ${Style.album_full_view_header_btn}`}
                  onClick={() => handleInfoShow()}
                  onMouseEnter={() => {
                    setNavAreaRightHover(true);
                    setNavHover(true);
                  }}
                  onMouseLeave={() => {
                    setNavAreaRightHover(false);
                    setNavHover(false);
                  }}
                >
                  <Icons icon={"info"} size={20} /> More Info
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {isZoom ? (
          <Zoom
            imgData={data[slideCount]?.src}
            zoomCount={zoomCount}
            setZoomCount={setZoomCount}
            currentIndex={slideCount}
            allData={data}
            handleClose={() => handleEscape()}
          />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <div className="custom_fraction" />
                <Swiper
                  ref={swiperRef}
                  initialSlide={showFullView.index}
                  onSwiper={setFullView}
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  pagination={{
                    el: ".custom_fraction",
                    type: "fraction",
                  }}
                  effect={"fade"}
                  spaceBetween={10}
                  //  loop={true}
                  navigation={true}
                  thumbs={{ swiper: fullViewThumbs }}
                  lazy={true}
                  modules={[
                    Lazy,
                    FreeMode,
                    Navigation,
                    Thumbs,
                    EffectFade,
                    Pagination,
                  ]}
                  className={Style.full_view_slider}
                  onSlideChange={(swiperCore) => {
                    const { activeIndex } = swiperCore;
                    setFullViewThumbIndex(activeIndex);
                    setInitialIndex(activeIndex);
                  }}
                >
                  {data?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        {index == fullViewThumbIndex ? (
                          <FullViewImage
                            allData={data}
                            imgIndex={index}
                            initialIndex={fullViewThumbIndex}
                            count={slideCount}
                            data={item}
                            onClick={() => handleZoom()}
                            handleDeleteAction={handleOpenDeleteModal}
                            show={fullViewThumbIndex}
                            frameData={{}}
                          />
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className={Style.full_view_slider_thumb_wrap}>
                  <Swiper
                    onSwiper={setFullViewThumbs}
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    freeMode={false}
                    watchSlidesProgress={true}
                    lazy={{
                      loadPrevNext: true,
                      loadPrevNextAmount: "11",
                    }}
                    modules={[
                      Lazy,
                      FreeMode,
                      Navigation,
                      Thumbs,
                      Pagination,
                      EffectCoverflow,
                    ]}
                    className={Style.full_view_slider_thumb}
                    draggable={false}
                    allowTouchMove={false}
                    preventClicks={true}
                    centeredSlides={true}
                    onSlideChange={(e) => slideChangeHandler(e)}
                  >
                    {data?.map((item, index) => {
                      let newThumb = item?.src?.thumbnail
                        ? item?.src?.thumbnail
                        : item?.src?.thumbnail2;
                      return (
                        <SwiperSlide
                          data-index={index}
                          key={index}
                          className={
                            index - fullViewThumbIndex >= 6
                              ? `slide_num_next_7`
                              : fullViewThumbIndex - index >= 6
                              ? `slide_num_prev_7`
                              : index > fullViewThumbIndex
                              ? `slide_num_next_${index - fullViewThumbIndex}`
                              : `slide_num_prev_${fullViewThumbIndex - index}`
                          }
                          onClick={(e) => {
                            handleClick(e);
                          }}
                        >
                          {index >= initialIndex - 5 &&
                          index <= initialIndex + 5 ? (
                            <figure key={item?.id}>
                              <img src={newThumb} alt="" />
                            </figure>
                          ) : (
                            ""
                          )}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </>
            ) : (
              ""
            )}

            <Offcanvas
              show={show}
              onHide={handleInfoClose}
              className={Style.info_bar}
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>More Info</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className={`${Style.info_tags} ${Style.info_sec}`}>
                  <h4
                    className={`${Style.info_tags_title} ${Style.info_sec_title}`}
                  >
                    Edit Tags
                  </h4>
                  <div
                    className={`${Style.tag_selection} ${Style.info_sec_content}`}
                  >
                    <ReactTags
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      inputFieldPosition="bottom"
                      autocomplete
                      // editable
                      removeComponent={RemoveComponent}
                    />
                  </div>
                </div>
                <Details data={data} fullViewThumbIndex={fullViewThumbIndex} />
              </Offcanvas.Body>
            </Offcanvas>
          </>
        )}

        <ShareModal
          openShare={openShare}
          setOpenShare={setOpenShare}
          from="zoomView"
          secretToken={secretToken}
          path={"view-content"}
        />
      </div>
    </>,
    document.getElementById("slideshow-root")
  );
};
export default FullscreenPreview;
