import React, { useEffect, useState } from "react";
import Controls from "./controls";
import { useVideoPlayer } from "../../Logic/useVideoPlayer";
import ReactPlayer from "react-player/lazy";
import Style from "./VideoPlayer.module.scss";
import FPALoader from "../../components/FPALoader";

const VideoPlayer = ({ vidUrl, play, setPlay, autoplay, loop, isMuted }) => {
  const {
    player,
    currentDuration,
    totalDuration,
    progress,
    secondsToTime,
    duration,
    seekHandler,
    fullScreenHandler,
    volume,
    fullScreen,
    setVolume,
    setCurrentDuration,
    playBackSpeedHandler,
    handleSeekMouseDown,
    handleSeekMouseUp,
  } = useVideoPlayer(play, setPlay);

  const [enableVideoSeek, setEnableVideoSeek] = useState();
  const [showbuffering, setShowbuffering] = useState(true);
  const [videoReady, setVideoReady] = useState(false);
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 32) {
        setPlay(!play);
      }
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    autoplay ? setPlay(true) : setPlay(false);
    //eslint-disable-next-line
  }, []);

  const handleRewind = () => {
    setCurrentDuration("0.00");
    setPlay(!play);
    setEnableVideoSeek(true);
  };

  const handleVideoBuffer = (e) => {
    setShowbuffering(true);
  };

  const handleVideoBufferEnd = (e) => {
    setShowbuffering(false);
  };

  const handleVideoReady = (e) => {
    setShowbuffering(false);
    setVideoReady(true);
  };
  const handleVideoOnPlay = (e) => {
    setShowbuffering(false);
  };

  const handleMouseEnter = () => {
    document
      .querySelector(".custom_player")
      .classList.add(`${Style.hide_control}`);
  };
  const handleMouseLeave = () => {
    // setOnHover(false);
  };

  return (
    <div
      className={`${Style.player_custom} ${Style.hide_control} custom_player`}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReactPlayer
        id="myvideo"
        url={vidUrl}
        ref={player}
        onBuffer={handleVideoBuffer}
        onBufferEnd={handleVideoBufferEnd}
        onReady={handleVideoReady}
        onPlay={handleVideoOnPlay}
        width="100%"
        controls={false}
        playing={play}
        volume={volume}
        onProgress={progress}
        onDuration={duration}
        muted={isMuted}
        loop={loop}
        config={{
          vimeo: {
            playerOptions: {
              quality: "360p",
            },
          },
        }}
      />
      {videoReady ? (
        <Controls
          play={play}
          setPlay={setPlay}
          currentDuration={currentDuration}
          secondsToTime={secondsToTime}
          totalDuration={totalDuration}
          seekHandler={seekHandler}
          fullScreen={fullScreen}
          fullScreenHandler={fullScreenHandler}
          playBackSpeedHandler={playBackSpeedHandler}
          volume={volume}
          setVolume={setVolume}
          reWindVideo={handleRewind}
          tabIndex={0}
          enableSeek={enableVideoSeek}
          showbuffering={showbuffering}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekMouseUp={handleSeekMouseUp}
        />
      ) : (
        <FPALoader />
      )}
      {/* {totalDuration > 0 ? ( */}
      {/* ) : (
        ""
      )} */}
    </div>
  );
};

export default VideoPlayer;
