import Style from "./VideoGallery.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DeleteModal from "./DeleteModal";
import VideoPreviewModal from "../VideoPreviewModal";
import HeaderFilter from "./HeaderFilters";
import VideoItem from "./VideoItem";
import VideoModal from "./VideoModal";
import { useVideos } from "./useVideos";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";

const VideoGallery = ({}) => {
  const { t } = useTranslation(["dashboard"]);

  const {
    resultData,
    status,
    videoActions,
    isDeletingLink,
    previewActions,
    NodataContent,
    handleClosePreview,
    handleCloseVideoModal,
    handleEdit,
    handleDelete,
    handleCloseDeleteModal,
    handleDeleteVideoLink,
    handleOpenPreview,
  } = useVideos();
  return (
    <>
      <div className={Style.my_gallery}>
        <div className={Style.my_gallery_header}>
          <div className={Style.my_gallery_header_wrap}>
            <h1 className={Style.my_gallery_title}>{t("My Video Links")}</h1>
            <div className={Style.my_gallery_btn_wrap}>
              <HeaderFilter />
            </div>
          </div>
        </div>
        <div className={Style.VideoGallery_item_container}>
          <div className={Style.VideoGallery_item_row}>
            {resultData?.data?.map((item, index) => {
              return (
                <VideoItem
                  id={item?.id}
                  title={item?.title}
                  content={item?.description}
                  image={item?.thumbnail}
                  handleEdit={() => handleEdit(item)}
                  handleDelete={() => handleDelete(item)}
                  handleOpenPreview={() => handleOpenPreview(item)}
                />
              );
            })}
          </div>
          {status == "loading" ? (
            <DashboardLoader data={resultData?.data} />
          ) : (
            ""
          )}
          {resultData?.data?.length == 0 &&
            status == "succeeded" &&
            NodataContent?.title != undefined && (
              <GalleryBlankPage
                title={NodataContent?.title}
                description={NodataContent?.description}
                // buttonText={NodataContent?.buttonText}
                // handleClick={() =>
                //   dispatch(openUploadModalAction({ data: true, path: path }))
                // }
              />
            )}
        </div>
      </div>
      <VideoModal
        open={videoActions?.isOpenCreationModal}
        close={handleCloseVideoModal}
      />
      <DeleteModal
        open={videoActions?.isOpenDeleteModal}
        close={() => handleCloseDeleteModal()}
        handledeleteItem={() => handleDeleteVideoLink()}
        status={isDeletingLink}
      />
      <VideoPreviewModal
        open={previewActions?.isOpen}
        close={() => handleClosePreview()}
        data={previewActions}
      />
    </>
  );
};

export default VideoGallery;
