import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedImage } from "../../../store/slices/GalleryTabSlice";

export const useAlbumGridView = (default_album_id) => {
  const dispatch = useDispatch();
  const selections = useSelector((state) => state.galleryTab);
  const albums = useSelector((state) => state?.album);

  const handleClickSelection = (item) => {
    if (
      selections?.ImageAlbumTab?.selction &&
      item?.id != default_album_id &&
      !item?.locked &&
      !item?.photofind_lock &&
      !item?.photofind_album &&
      !item?.default
    ) {
      dispatch(SelectedImage({ tab: "album", imageId: item?.id }));
    }
  };

  return {
    albums,
    selections,
    handleClickSelection,
  };
};
