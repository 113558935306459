import Style from "./Menu.module.scss";
import { Dropdown, Button, Container } from "react-bootstrap/";
import { Link, Navigate, useLocation } from "react-router-dom";
import Assets from "../Assets";
import Icons from "../Icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../Modals/commonModal";
import { useEffect, useState } from "react";
import {
  authModalHandler,
  changeScreen,
  logout,
} from "../../../store/slices/authSlice";
import { useDimensions } from "../../../Logic/Dimensions";
import {
  clearProfileState,
  getProfileDetails,
} from "../../../store/slices/userprofileSlice";
import avatar from "../../../assets/avatar.png";
import { useNavigate } from "react-router-dom";
import useAuthChecker from "../../../Logic/useAuthChecker";
import DarkThemeToggler from "../../DarkThemeToggler";
import SearchBar from "../../SearchBar";
import useDarkmode from "../../../utils/utilityHooks/useDarkmode";
import { clearGalleryState } from "../../../store/slices/gallerySlice";
import { useUserChecker } from "../../../Logic/AuthChecker/useUserChecker";
import { useRegister } from "../../../Logic/useRegister";
import LanguageMenu from "../LanguageMenu/LanguageMenu";
import FullscreenPreviewNew from "../../Gallery/FullScreenPreviewNew";
import { useFullscreenPreviewActions } from "../../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { useQuickSlideShowActions } from "./useQuickSearchSlideShow";
import QuickSlideShow1 from "../../Slideshow/QuickSlideShow1";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";

const Menu = () => {
  const { registerData, registerStatus } = useSelector((state) => state.auth);
  const { userdata } = useSelector((state) => state.userprofile);
  const { theme, themeToggler } = useDarkmode();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthCompleted } = useAuthChecker();

  const [openModal, setOpenModal] = useState(false);
  const [hasNotification, setHasNotification] = useState(true);

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const { handleGetProfileDetails } = useUserChecker();

  const { showModal, setShowModal } = useRegister();

  const [language, setLanguage] = useState("English");
  const { i18n, lt } = useTranslation(["common"]);
  const languageSwitch = (language, flag, code) => {
    setLanguage(language);
    // setFlag(flag);
    i18n.changeLanguage(code);
  };
  const isDark = document.body.classList.contains("dark");

  const [showMenu, setShowMenu] = useState(false);
  const [showLang, setShowLang] = useState(false);
  // useEffect(() => {
  //   showModal && setShowMenu(false);
  // }, [showModal]);

  useEffect(() => {
    // if (registerData?.name && userdata?.profile_image == undefined) {
    handleGetProfileDetails();
    // }
  }, []);

  const handleLogout = () => {
    dispatch(clearProfileState());
    dispatch(logout());
    dispatch(clearGalleryState());
  };

  const { t } = useTranslation(["dashboard"]);
  const windowDimensions = useDimensions();

  const [searchCategory, setSearchCategory] = useState("Photos");

  const searchCategorySwitch = (category) => {
    setSearchCategory(category);
  };

  const handleClickLogo = () => {
    navigate("/");
    dispatch(authModalHandler(false));
    dispatch(changeScreen(""));
  };

  const { results, isLoading } = useSelector((state) => state.search);

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView, setShowFullView);

  let previewData = {
    images: results,
    pathFrom: "searchlist",
    section: "all",
  };

  let slideData = {
    images: results,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };

  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(showFullView.isOpen);
  }, [showFullView.isOpen]);

  return (
    <>
      <header className={Style.loggedin_header}>
        <Container className={Style.loggedin_header_container} fluid>
          {windowDimensions.width >= 1280 ? (
            <>
              <div className={Style.loggedin_header_wrap}>
                <div className={Style.loggedin_header_left}>
                  <figure className={Style.loggedin_header_logo}>
                    <a
                      onClick={() => handleClickLogo()}
                      title={"Family Photos"}
                    >
                      <img
                        src={Assets.fpa_main_logo_dark}
                        alt="Family Photos"
                      />
                    </a>
                  </figure>
                  <figure className={Style.loggedin_header_logo}>
                    <Link to={"/"} title={"Family Photos"}>
                      <img src={Assets.fpa_main_logo} alt="Family Photos" />
                    </Link>
                  </figure>
                  <SearchBar
                    searchCategory={searchCategory}
                    searchCategorySwitch={searchCategorySwitch}
                    extraClass={""}
                    noDark={false}
                    hasMobile={true}
                    openPreview={openPreview}
                  />
                </div>
                <nav className={Style.loggedin_header_nav}>
                  {(location.pathname.includes("/dashboard") &&
                    !location.pathname.includes("/dashboard/preference")) ||
                  location.pathname === "/family-tree-form" ? (
                    <DarkThemeToggler
                      className={Style.menu_dark_theme_toggler}
                      text="none"
                      theme={theme}
                      toggleTheme={themeToggler}
                    />
                  ) : (
                    ""
                  )}
                  {/* <Button
                    variant="primary"
                    className={`${
                      Style.btn_create_tree
                    } ${"btn btn-secondary"}`}
                    onClick={() => handleClickCreateTree()}
                  >
                    <Icons icon={"add"} size={14} />
                    {t("create_a_tree")}
                  </Button> */}

                  {/* Uncomment to enable notifications */}

                  {/* <div
                    className={`${Style.bell_icon} ${
                      hasNotification && Style.active
                    }`}
                  >
                    <Icons icon={"bell-icon"} size={24} />
                  </div> */}

                  <Dropdown>
                    <Dropdown.Toggle className={Style.profile_dropdown}>
                      <figure className={Style.profile_image}>
                        <img
                          src={
                            isAuthCompleted &&
                            userdata?.profile_image?.thumbnail
                              ? userdata?.profile_image?.thumbnail
                              : avatar
                          }
                          alt="profile-pic"
                        />
                      </figure>
                      <div className={Style.profile_details}>
                        <span className={Style.username}>
                          {isAuthCompleted && userdata?.name ? (
                            userdata?.name?.length <= 10 ? (
                              userdata?.name
                            ) : (
                              `${userdata?.name?.substring(0, 12)} ${
                                userdata?.name?.length > 12 ? "..." : ""
                              }`
                            )
                          ) : (
                            <div className={Style.profile_name_shimmer}></div>
                          )}
                          {/* {isAuthCompleted && userdata?.full_name
                            ? userdata?.full_name
                            : "Admin"} */}
                        </span>
                        {/* <span className={Style.ownership}>Admin</span> */}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {location.pathname == "/dashboard" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard">
                          Dashboard
                        </Dropdown.Item>
                      )}

                      {location.pathname == "/dashboard/user-profile" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard/user-profile">
                          My Profile
                        </Dropdown.Item>
                      )}
                      {location.pathname == "/dashboard/archive" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard/hidden">
                          Hidden Photos
                        </Dropdown.Item>
                      )}
                      {location.pathname == "/dashboard/trash" ? (
                        ""
                      ) : (
                        <Dropdown.Item as={Link} to="/dashboard/trash">
                          Trash
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </nav>
              </div>
            </>
          ) : (
            <>
              <div className={Style.loggedin_header_wrap_mob}>
                {isSearchEnabled ? (
                  <>
                    <SearchBar
                      searchCategory={searchCategory}
                      searchCategorySwitch={searchCategorySwitch}
                      extraClass={"menu-search"}
                      noDark={false}
                      autoFocus={true}
                      fullscreenResults={true}
                      hasMobile={true}
                      openPreview={openPreview}
                    />
                    <button
                      className="search-close-btn"
                      onClick={() => setIsSearchEnabled(false)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <div>
                      <button
                        className={Style.prelogin_mobile_header_hamburger}
                        onClick={() => setShowMenu(true)}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                    <div className={Style.header_right_btn_wrap_mob}>
                      {/* {location.pathname.includes("/dashboard") ||
                      location.pathname === "/family-tree-form" ? (
                        <DarkThemeToggler
                          className={Style.menu_dark_theme_toggler}
                          text="none"
                          theme={theme}
                          toggleTheme={themeToggler}
                        />
                      ) : (
                        ""
                      )} */}
                      {/* <Button
                        variant="primary"
                        className={`${Style.btn_create_tree_mob} ${"btn"}`}
                        onClick={() => handleClickCreateTree()}
                      >
                        <Icons icon={"add"} size={14} />
                      </Button> */}
                      <button
                        className={Style.search_bar_search_icon_mob}
                        onClick={() => setIsSearchEnabled(true)}
                      >
                        <Icons icon={"search"} size={22} />
                      </button>
                      {/* Uncomment to enable notifications */}
                      {/* <div className={`${Style.bell_icon} ${Style.active}`}>
                          <Icons icon={"bell-icon"} size={24} />
                        </div> */}

                      <Dropdown className={Style.loggedin_header_wrap_mob_wrap}>
                        <Dropdown.Toggle className={Style.profile_dropdown_mob}>
                          <figure className={Style.profile_image}>
                            <img
                              src={
                                isAuthCompleted &&
                                userdata?.profile_image?.thumbnail
                                  ? userdata?.profile_image?.thumbnail
                                  : avatar
                              }
                              alt="profile-pic"
                            />
                          </figure>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {location.pathname == "/dashboard" ? (
                            ""
                          ) : (
                            <Dropdown.Item as={Link} to="/dashboard">
                              Dashboard
                            </Dropdown.Item>
                          )}

                          {location.pathname == "/dashboard/user-profile" ? (
                            ""
                          ) : (
                            <Dropdown.Item
                              as={Link}
                              to="/dashboard/user-profile"
                            >
                              My Profile
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item as={Link} to="/">
                            Home
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Container>
        <CommonModal
          showModal={openModal}
          hide={() => setOpenModal(false)}
          className="modal-width logout-modal"
          content={
            <>
              <div className={Style.logoutSection}>
                <div className={`${Style.title} mb-4`}>
                  Are You Sure To Logout?
                </div>

                <div
                  className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
                >
                  <button
                    onClick={() => handleLogout()}
                    className="btn btn-primary"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="btn btn-light"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          }
        />
      </header>
      {windowDimensions.width < 1280 ? (
        <>
          <nav
            className={`${Style.prelogin_mobile_header_menu} ${
              showMenu ? Style.menu_open : ""
            }`}
          >
            <div className={Style.prelogin_mobile_header_logo_wrap}>
              <figure className={Style.loggedin_header_logo}>
                <a onClick={() => handleClickLogo()} title={"Family Photos"}>
                  <img src={Assets.fpa_main_logo_dark} alt="Family Photos" />
                </a>
              </figure>
              <figure className={Style.loggedin_header_logo}>
                <Link to={"/"} title={"Family Photos"}>
                  <img src={Assets.fpa_main_logo} alt="Family Photos" />
                </Link>
              </figure>

              <button
                className={Style.close_menu}
                onClick={() => setShowMenu(false)}
              >
                <Icons icon={"icon-close"} size={18} />
              </button>
            </div>

            <div className="container">
              <div className={Style.prelogin_mobile_header_menu_wrap}>
                <ul className={Style.prelogin_mobile_header_links}>
                  <li
                    className={
                      location.pathname == "/dashboard" ? Style.active : ""
                    }
                  >
                    <Link to={"/dashboard"} onClick={() => setShowMenu(false)}>
                      <span>
                        <Icons icon={"icon-image"} size={20} />
                      </span>{" "}
                      My Gallery
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname == "/dashboard/family-tree"
                        ? Style.active
                        : ""
                    }
                  >
                    <Link
                      to={"/dashboard/family-tree"}
                      onClick={() => setShowMenu(false)}
                    >
                      <span>
                        <Icons icon={"icon-people"} size={20} />
                      </span>{" "}
                      Family Tree
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname == "/dashboard/user-profile"
                        ? Style.active
                        : ""
                    }
                  >
                    <Link
                      to={"/dashboard/user-profile"}
                      onClick={() => setShowMenu(false)}
                    >
                      <span>
                        <Icons icon={"icon-user"} size={20} />
                      </span>{" "}
                      My profile
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname == "/dashboard/preference"
                        ? Style.active
                        : ""
                    }
                  >
                    <Link
                      to={"/dashboard/preference"}
                      onClick={() => setShowMenu(false)}
                    >
                      <span>
                        <Icons icon={"icon-settings"} size={20} />
                      </span>{" "}
                      Preference
                    </Link>
                  </li>
                </ul>
                <div>
                  <div className={Style.DarkThemeToggler_menu}>
                    <span className={Style.DarkThemeToggler_menu_icon_wrap}>
                      <Icons
                        icon={`${theme == "dark" ? "icon-sun" : "icon-moon"}`}
                        size={26}
                      />
                    </span>
                    <span>
                      {theme == "dark" ? <>Light mode</> : <>Dark mode</>}
                    </span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        onChange={() => themeToggler()}
                        checked={theme == "dark" ? true : false}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div
            className={Style.menu_overlay}
            onClick={() => setShowMenu(false)}
          ></div>
        </>
      ) : (
        <></>
      )}

      {showFullView.isOpen && (
        <FullscreenPreviewNew
          previewObj={previewData}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
          total={previewData?.images?.length}
        />
      )}

      {quickSlideShow?.searchSlideActions?.isOpen &&
        slideData?.images?.length > 0 && (
          <QuickSlideShow1
            slideObj={slideData}
            close={closeQuickSlideShow}
            initialIndex={showFullView?.index}
          />
        )}
    </>
  );
};

export default Menu;
