import React, { useEffect, useState } from "react";
import Style from "./SearchBar.module.scss";
import { Dropdown } from "react-bootstrap";
import Icons from "../Layouts/Icons";
import { Link, useLocation, useParams } from "react-router-dom";
import Assets from "../Layouts/Assets";
import { useSearch } from "../../Logic/useSearch";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FullscreenPreview from "../Gallery/FullscreenPreview";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import { useTranslation } from "react-i18next";
import FullscreenPreviewNew from "../Gallery/FullScreenPreviewNew";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import { useFullscreenPreviewActions } from "../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { useQuickSlideShowActions } from "../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import { useRef } from "react";

var currentHoveringElement = "";

const SearchBar = ({
  extraClass,
  noDark,
  autoFocus,
  fullscreenResults,
  hasMobile,
  openPreview,
}) => {
  const {
    capitalizeFirstLetter,
    handleSearchOpen,
    isSuggestOpen,
    setIsSuggestOpen,
    searchQuery,
    searchOption,
    setSearchOption,
    setSearchQuery,
    isRecentSearch,
    setIsRecentSearch,
    customSearchData,
    setCustomSearchData,
    searchCategory,
    setSearchCategory,
    debouncedSearchTerm,
    callSearchAPI,
    isSerachOnFocus,
    setIsSerachOnFocus,
    loadCount,
    searchInput,
    _handleKeyDown,
  } = useSearch();

  const location = useLocation();
  const navigate = useNavigate();
  const { results, isLoading } = useSelector((state) => state.search);
  // const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });
  const { t } = useTranslation();

  const { query, option } = useParams();
  const dispatch = useDispatch();

  const handleClickResults = (data) => {
    if (searchQuery) {
      navigate(
        `/dashboard/searchresult/${searchQuery.trim()}/${searchCategory.toLowerCase()}`
      );
      setTimeout(() => {
        setIsSuggestOpen(false);
      }, 100);
    }
  };

  document.addEventListener("mouseover", function (e) {
    try {
      currentHoveringElement = e.target;
    } catch (error) {}
  });

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions();

  const SearchSuggestOpenBlurEvent = function (e) {
    setIsSerachOnFocus(false);

    if (currentHoveringElement != "") {
      if (
        document
          .getElementsByClassName("search-suggestion-wrapper")[0]
          ?.contains(currentHoveringElement) === false
      ) {
        setTimeout(() => {
          setIsSuggestOpen(false);
        }, 100);
      } else {
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (searchInput.current) {
        searchInput.current.disabled = false;
      }
    }, 300);
  }, []);
  const [showResults, setShowResults] = useState(false);
  const searchContainerRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSuggestOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(
    function () {
      setSearchOption(searchCategory);
    },
    [searchCategory]
  );

  const getOptionLabel = function (option) {
    switch (option) {
      case "Photos":
        return "Image";
        break;
      case "Albums":
        return "Album";
        break;
      default:
        return "";
    }
  };

  const SearchBarItemsCreate = (results) => {
    if (isLoading === false) {
      if (results?.length > 0) {
        return results.map((data, index) => {
          return (
            data.locked != 1 && (
              <li className={Style.search_profile} key={data.id}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    if (searchOption === "Albums") {
                      navigate(`/dashboard/album/${data.id}`);
                    } else if (searchOption === "Photos") {
                      openPreview(index, data);
                      // handleOpenFullViewModal(index, data);
                      // navigate(`/dashboard/imageview/${data.id}`);
                    }

                    setTimeout(() => {
                      setIsSuggestOpen(false);
                    }, 100);
                  }}
                >
                  <figure className={Style.search_profile_image}>
                    <img
                      src={
                        data.src.thumbnail === null
                          ? Assets.search_borken_placeholder
                          : data.src.thumbnail
                      }
                      alt="profile-img"
                    />
                  </figure>
                  <div className={Style.search_profile_data}>
                    <div
                      className={`${Style.search_profile_name} search-list-item`}
                    >
                      {capitalizeFirstLetter(data.title)}
                    </div>
                    <div className={Style.search_profile_address}>
                      {getOptionLabel(searchOption)}
                    </div>
                  </div>
                </a>
              </li>
            )
          );
        });
      }
    } else {
      return (
        <div className={Style.search_result_not_found}>
          <InfiniteDotLoader />
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`${Style.search_bar_search} ${extraClass} ${
          !hasMobile ? Style.no_mobile : ""
        } ${noDark ? Style.no_dark : ""} ${
          location.pathname.includes("/dashboard") ||
          location.pathname === "/get-started" ||
          location.pathname === "/choose-plan"
            ? Style.menu_search
            : ""
        }`}
      >
        <div className={Style.search_bar_search_icon}>
          <Icons icon={"search"} size={17} />
        </div>
        <input
          type="text"
          ref={searchInput}
          placeholder="Search by tag, date & location"
          className={Style.search_bar_search_input}
          onKeyDown={(e) => _handleKeyDown(e)}
          //onKeyUp={(e) => handleSearchOpen(e)}
          onBlur={SearchSuggestOpenBlurEvent}
          onFocus={() => {
            if (debouncedSearchTerm != "") {
              setIsSuggestOpen(true);
            }
          }}
          onChange={(e) => handleSearchOpen(e)}
          autoComplete="new-password"
          autoFocus={autoFocus}
          value={searchQuery}
          disabled
        />
        <div className={Style.search_bar_search_dropdown}>
          <Dropdown style={{ width: "100%" }}>
            <Dropdown.Toggle
              className={`${Style.search_bar_toggle} ${Style.search_bar_toggle}`}
            >
              {searchCategory}
              <Icons icon={"next-arrow"} size={12} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setSearchCategory("Photos");
                  setSearchOption("Photos");
                }}
              >
                {t("photos")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSearchOption("Albums");
                  setSearchCategory("Albums");
                }}
              >
                {t("albums")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {!fullscreenResults && isSuggestOpen && searchQuery.length > 0 && (
          <div
            className={`${Style.search_bar_search_suggestions} search-suggestion-wrapper`}
            ref={searchContainerRef}
          >
            {isRecentSearch && false && (
              <div className={Style.recent_search_header}>
                <h4 className={Style.recent_search_title}>Recent searches</h4>
                <button className={Style.recent_search_clear}>Clear</button>
              </div>
            )}
            <ul className={`${Style.search_bar_search_suggestions_list}`}>
              {results?.length == 0 && searchQuery.length > 0 ? (
                <div className={Style.search_result_not_found}>
                  "{searchQuery}" not found...
                </div>
              ) : (
                SearchBarItemsCreate(results)
              )}
            </ul>
            {!isRecentSearch && customSearchData.length > 0 && (
              <ul className={Style.search_bar_search_suggestions_tags}>
                <li>
                  <button>Cannon</button>
                </li>
                <li>
                  <button>London</button>
                </li>
                <li>
                  <button>2021</button>
                </li>
                <li>
                  <button>Flower Garden</button>
                </li>
              </ul>
            )}
            {results?.length === 5 && !isLoading && (
              <div className={Style.view_all_btn_wrap}>
                <button
                  disabled={searchQuery?.length == 0}
                  onClick={handleClickResults}
                  className={Style.view_all_btn}
                >
                  View All
                  <Icons icon={"back-arrow"} size={16} />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {fullscreenResults && isSuggestOpen && searchQuery?.length !== 0 && (
        <div className={Style.search_bar_search_suggestions}>
          {isRecentSearch && false && (
            <div className={Style.recent_search_header}>
              <h4 className={Style.recent_search_title}>Recent searches</h4>
              <button className={Style.recent_search_clear}>Clear</button>
            </div>
          )}
          {!isRecentSearch && customSearchData.length > 0 && false && (
            <>
              <h5 className={Style.search_bar_search_suggestions_tags_title}>
                Related tags
              </h5>
              <ul className={Style.search_bar_search_suggestions_tags}>
                <li>
                  <button>Cannon</button>
                </li>
                <li>
                  <button>London</button>
                </li>
                <li>
                  <button>2021</button>
                </li>
                <li>
                  <button>Flower Garden</button>
                </li>
              </ul>
            </>
          )}
          {results?.length > 5 && !isLoading && (
            <div className={Style.view_all_btn_wrap}>
              <button
                disabled={searchQuery?.length == 0}
                onClick={handleClickResults}
                className={Style.view_all_btn}
              >
                View All
                <Icons icon={"back-arrow"} size={16} />
              </button>
            </div>
          )}
          <ul className={Style.search_bar_search_suggestions_list}>
            {results.length > 0 ? (
              SearchBarItemsCreate(results)
            ) : (
              <div className={Style.search_result_not_found}>
                Search result not found
              </div>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchBar;
