import React from "react";
import ImageListMasonry from "../../../ImageListMasonry";
import Style from "./AlbumImage.module.scss";
import Assets from "../../../Layouts/Assets";
import Icons from "../../../Layouts/Icons";
import { useDispatch } from "react-redux";
import { useQuickSlideShowActions } from "../../../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import { useFullscreenPreviewActions } from "../../../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { DashboardLoader } from "../../../DashboardLoader";

const AlbumImageContainer = ({ data, isInvalid, isLoading }) => {
  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const { openQuickSlideShow } = useQuickSlideShowActions(
    showFullView,
    setShowFullView
  );
  return (
    <>
      {isInvalid ? (
        <div className={Style.noData}>
          <img src={Assets?.link_broken} alt="" />

          <h4>Your link is invalid.</h4>
          <p>please check link and token</p>
        </div>
      ) : isLoading ? (
        <div className={Style.loaderWindow}>
          <DashboardLoader />
        </div>
      ) : (
        <>
          <div className={Style.albumDetails}>
            <div className={Style.info}>
              <h2>
                {data?.album?.title}
                <span>{`${
                  data?.images?.length ? data?.images?.length : 0
                }`}</span>
              </h2>
              <p>{data?.album?.description} </p>
            </div>
            <button
              className={Style.album_full_view_header_btn_mob}
              onClick={openQuickSlideShow}
            >
              <Icons icon={"slideshow"} size={17} />
              <span> Slide Show</span>
            </button>
          </div>
          <div style={{ padding: "10px 0", height: "100vh" }}>
            <ImageListMasonry
              total={data?.images?.length}
              Images={data?.images}
              loadMore={{}}
              NodataContent={{}}
              options={[]}
              moveListItem={{}}
              albumData={data?.album}
              frames={[]}
              mode="album"
              disableControls={true}
              isShare={true}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AlbumImageContainer;
