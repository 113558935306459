import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getVersionHistories,
  deleteVersionHistory,
  restoreVersion,
} from "../../../store/slices/familyTreeSlice";
import useDebounce from "../../../utils/utilityHooks/useDebounce";

export const useVersionHistory = ({ handleUpdate, handleClose }) => {
  const dispatch = useDispatch();
  const {
    isFetchingHistory,
    isDeletingHistory,
    versionHistory,
    isRestoringVersion,
    isFetchingVersionTitle
  } = useSelector((state) => state?.familyTree);

  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [historyActions, setHistoryActions] = useState({
    isShowRestoreBtn: false,
    itemId: null,
    selectedId: null,
    info: ""
  });

  useEffect(() => {
    // if (isFetchingHistory == "idle") {
    getVersions();
    // }
  }, []);

  const getVersions = async () => {
    const resultAction = await dispatch(getVersionHistories());
    if (getVersionHistories.fulfilled.match(resultAction)) {
      const foundObject = resultAction?.payload?.data?.result?.find(item => {
        return item?.versions?.some(version => version?.default === 1);
      })?.versions?.find(version => version?.default === 1);
      setHistoryActions({
        isShowRestoreBtn: false,
        itemId: foundObject?.id,
        selectedId: null,
      })
    }
  };



  const showToast = (msg) => {
    toast.info(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "bgsecondary",
      icon: false,
    });
  };

  const handleSelectItem = (item) => {
    setHistoryActions({
      isShowRestoreBtn: true,
      itemId: item?.id,
      selectedId: null,
      info: item.date
    });
    handleUpdate(item);
  };


  const handleDeleteVersion = async (id) => {
    const resultAction = await dispatch(deleteVersionHistory({ id: id }));
    if (deleteVersionHistory.fulfilled.match(resultAction)) {
      setOpenDeleteModal(false);
      getVersions();
    }
  };

  const handleOpenDeleteModal = (e, item) => {
    setHistoryActions({
      isShowRestoreBtn: false,
      itemId: null,
      selectedId: item?.id,
      info: item.date

    });
    setOpenDeleteModal(e);
  };

  const handleRestore = async (id) => {
    const resultAction = await dispatch(restoreVersion({ id: id }));
    if (restoreVersion.fulfilled.match(resultAction)) {
      setOpenRestoreModal(false);
      setHistoryActions({
        isShowRestoreBtn: false,
        itemId: id,
        selectedId: null,
        info: ""
      });
      handleClose()
      //   getVersions();
    }
  };




  return {
    versionHistory,
    historyActions,
    openRestoreModal,
    openDeleteModal,
    isDeletingHistory,
    isRestoringVersion,
    isFetchingHistory,
    showToast,
    handleSelectItem,
    setOpenRestoreModal,
    setOpenDeleteModal,
    handleDeleteVersion,
    handleOpenDeleteModal,
    handleRestore,
  };
};
