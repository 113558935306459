import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { verifyOtpForEmailUpdate } from "../store/slices/emailUpdateOtpSlice";
import { UpdateEmail } from "../store/slices/profileInfoUpdateSlice";

export const useEmailUpdateOtp = (ClosePopup) => {
  const [showOtpResponse, setShowOtpResponse] = useState({
    message: "",
    status: null,
  });
  const [verifyOtpStatus, setVerifyOtpStatus] = useState(false);
  const [code, setCode] = useState("");
  const [resetStatus, setResetStatus] = useState("");

  const { formSubmittedDetails } = useSelector(
    (state) => state.userEmailUpdate
  );
  const dispatch = useDispatch();

  const verifyForgotOtp = async (code) => {
    setShowOtpResponse({
      message: "",
      status: null,
    });
    setCode(code);
    if (code.length == 4) {
      const formData = new FormData();
      formData.append("otp", code);
      formData.append("token", formSubmittedDetails.token);
      formData.append("mail", formSubmittedDetails.mail);

      dispatch(verifyOtpForEmailUpdate(formData))
        .unwrap()
        .then((result) => {
          const { success, message, code, data } = result?.data;
          if (success == true) {
            dispatch(UpdateEmail(formSubmittedDetails.mail));
            // setVerifyOtpStatus(true);
            setShowOtpResponse({
              message: message,
              status: true,
            });

            setTimeout(() => {
              setShowOtpResponse({});
              ClosePopup();
            }, 3000);
          } else {
            setVerifyOtpStatus(false);
            setShowOtpResponse({
              message: message,
              status: false,
            });
          }
        })
        .catch((e) => {
        });
    } else {
    }
  };

  return {
    verifyForgotOtp,
    code,
    setShowOtpResponse,
    showOtpResponse,
    verifyOtpStatus,
  };
};
