import Style from "./UserProfileFamilyMembers.module.scss";
import { currentTabSelection } from "../../../store/slices/gallerySlice";
import UserProfileFamilyMember from "./UserProfileFamilyMember";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../../../Logic/useProfile";
import GalleryBlankPage from "../../BlankGallery";
import Assets from "../../Layouts/Assets";
import BlankFamilyMembers from "../../BlankFamilyMembers";

const UserProfileFamilyMembers = () => {
  const {
    familyMembers,
    TabRouteData,
    isDataExist,
    triggeruseEffectGetProfileDetails,
    triggeruseEffectFamilyMembers,
  } = useProfile();

  useEffect(() => {
    triggeruseEffectGetProfileDetails();
    triggeruseEffectFamilyMembers();
  }, []);

  return (
    <>
      <div className={Style.User_profile_overview_right_side}>
        <div className={Style.User_profile_overview_sec}>
          <h2 className={Style.User_profile_overview_title}>Family Members </h2>
          <>
            <div className={Style.user_profile_members}>
              <div
                className={`${Style.left_filtered_category_list_wrap} ${
                  isDataExist(familyMembers) !== true
                    ? Style.blank_contents
                    : ""
                }`}
              >
                {isDataExist(familyMembers) === true ? (
                  // <div className={Style.left_filtered_category_list}></div>
                  <div className={Style.left_filtered_category_members_list}>
                    {TabRouteData.map(() => {
                      return (
                        <div
                          className={Style.left_filtered_category_members_row}
                        >
                          {familyMembers.map((member, index) => (
                            <UserProfileFamilyMember
                              imgUrl={member.img}
                              memberName={member.name}
                              // memberRelation={capitalizeFirstLetter(
                              //   member.connection +
                              //     " " +
                              //     (familyMembers.length >= 2
                              //       ? index + 1
                              //       : "")
                              // )}
                            />
                          ))}
                        </div>
                      );
                      // if (typeof connection === "object") {
                      //   return (
                      //     <>
                      //       {is_parents_exist(familyMembers) && (
                      //         <h5
                      //           className={
                      //             Style.left_filtered_category_members_relation
                      //           }
                      //         >
                      //           {Title}
                      //         </h5>
                      //       )}
                      //       <div
                      //         className={Style.left_filtered_category_members_row}
                      //       >
                      //         {connection.parents.map(
                      //           (connection) =>
                      //             is_relation_exist(familyMembers, connection) && (
                      //               <UserProfileFamilyMember
                      //                 imgUrl={family_filter(
                      //                   familyMembers,
                      //                   connection,
                      //                   "img"
                      //                 )}
                      //                 memberName={family_filter(
                      //                   familyMembers,
                      //                   connection,
                      //                   "name"
                      //                 )}
                      //                 memberRelation={capitalizeFirstLetter(
                      //                   connection
                      //                 )}
                      //                 hasGotData={hasGotData}
                      //                 setHasGotData={setHasGotData}
                      //               />
                      //             )
                      //         )}
                      //       </div>
                      //     </>
                      //   );
                      // } else {
                      //   return (
                      //     <>
                      //       {family_filter_array(familyMembers, connection).length >
                      //         0 && (
                      //         <h5
                      //           className={
                      //             Style.left_filtered_category_members_relation
                      //           }
                      //         >
                      //           {capitalizeFirstLetter(connection)}
                      //         </h5>
                      //       )}

                      //       <div
                      //         className={Style.left_filtered_category_members_row}
                      //       >
                      //         {family_filter_array(familyMembers, connection).map(
                      //           (member, index) => (
                      //             <UserProfileFamilyMember
                      //               imgUrl={member.img}
                      //               memberName={member.name}
                      //               memberRelation={capitalizeFirstLetter(
                      //                 member.connection +
                      //                   " " +
                      //                   (family_filter_array(
                      //                     familyMembers,
                      //                     connection
                      //                   ).length >= 2
                      //                     ? index + 1
                      //                     : "")
                      //               )}
                      //             />
                      //           )
                      //         )}
                      //       </div>
                      //     </>
                      //   );
                      // }
                    })}
                  </div>
                ) : (
                  <BlankFamilyMembers
                    title={"No members in your tree"}
                    description={
                      "Add family members in your tree. Added members in the tree are listed here"
                    }
                    buttonText={"Add Members"}
                    className={Style.no_members_placeholder}
                    imagePath={Assets.file_upload_image}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
    // ) : (
    //   ""
  );
};

export default UserProfileFamilyMembers;
