import { Col, Form, Row } from "react-bootstrap";
import { useDimensions } from "../../../Logic/Dimensions";
import CommonModal from "../../Modals/commonModal";
import Style from "./AlbumEditModal.module.scss";
import { useAlbumEditModal } from "./useAlbumEditModal";
import Icons from "../../Layouts/Icons";
import FitToFrame from "../FitToFrame/FitToFrame";
import FrameItem from "../FrameItem";

const AlbumEditModal = ({ albumEditModal, setAlbumEditModal }) => {
  const {
    album,
    frames,
    selectedFrame,
    setSelectedFrame,
    frameData,
    setFrameData,
    handleAlbumEdit,
    isExistingName,
    showMsg,
    setShowMsg,
  } = useAlbumEditModal({ albumEditModal, setAlbumEditModal });

  const albumInfo = album?.resultData?.data?.filter(
    ({ title }) => title == frameData.title
  )[0];

  const windowDimensions = useDimensions();

  return (
    <CommonModal
      showModal={albumEditModal.popup}
      hide={() => setAlbumEditModal({ popup: false, album: {} })}
      className={`${Style.edit_my_album} ${
        albumEditModal?.album?.src?.large ? "" : Style.modalMini
      } modal-fullscreen`}
      content={
        <>
          <Row>
            {albumEditModal?.album?.src?.large ? (
              <Col md className={Style.edit_my_album_leftside}>
                {windowDimensions.width < 576 && (
                  <>
                    <div className={Style.edit_album_head}>
                      <button
                        className={Style.back_button}
                        onClick={() =>
                          setAlbumEditModal({ popup: false, album: {} })
                        }
                      >
                        <Icons icon={"back-arrow"} size={16} />
                      </button>
                      <h4 className="h5">{"Edit"} Album</h4>
                    </div>
                  </>
                )}
                <FitToFrame
                  frame={selectedFrame}
                  image={albumEditModal?.album?.src?.large}
                  frameData={frameData}
                  setFrameData={setFrameData}
                />
              </Col>
            ) : (
              ""
            )}

            <Col md className={Style.edit_my_album_rightside}>
              {windowDimensions.width >= 576 ? (
                <>
                  <h4 className="h5 mb-4"> {"Edit"} Album</h4>
                </>
              ) : (
                <></>
              )}
              <Form>
                <Form.Group className="mb-4" controlId="formTitle">
                  <Form.Label className="mb-1">Album Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="New Album 01"
                    onChange={(e) => {
                      setFrameData({ ...frameData, title: e.target.value });
                      setShowMsg(false);
                    }}
                    value={frameData?.title}
                    maxLength={40}
                  />
                  {frameData?.title?.length == 0 ? (
                    <div className={Style.error_msg}>
                      {"Album name is required"}
                    </div>
                  ) : isExistingName && showMsg ? (
                    <div className={Style.error_msg}>
                      {"Album name already exists"}
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicCheckbox">
                  <Form.Label className="mb-1">Description</Form.Label>
                  <div className="textarea">
                    <Form.Control
                      data-lenis-prevent
                      as="textarea"
                      name="description"
                      rows={3}
                      placeholder="Add a description"
                      onChange={(e) =>
                        setFrameData({
                          ...frameData,
                          description: e.target.value,
                        })
                      }
                      value={frameData?.description}
                    />
                  </div>
                </Form.Group>
                {albumEditModal?.album?.src?.large && (
                  <Form.Group className="mb-4">
                    <Form.Label className="mb-1">Add Frame</Form.Label>
                    <div
                      className={Style.select_a_frame_col}
                      data-lenis-prevent
                    >
                      <div className={Style.select_a_frame_row}>
                        {frames?.map((item, index) => {
                          return (
                            <FrameItem
                              frameSrc={item?.frame}
                              frameTitle={item?.frameName}
                              frameSelected={
                                item?.id === selectedFrame.id ? true : false
                              }
                              key={index}
                              onClickfun={() => setSelectedFrame(item)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Form.Group>
                )}
                {/* isAlbumEditing != "loading" &&  */}

                <button
                  disabled={
                    frameData?.title?.length == 0 ||
                    frameData?.title.trim() !== frameData?.title ||
                    isExistingName
                  }
                  onClick={(e) => handleAlbumEdit(e)}
                  className={`w-100 ${Style.btnItem} ${
                    album.editStatus == "loading" && "btn-loader-active"
                  }`}
                >
                  {album.editStatus !== "loading" && "Continue"}
                </button>
              </Form>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default AlbumEditModal;
