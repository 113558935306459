import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlbums,
  createAlbum,
  editAlbum,
} from "../../../../../store/slices/albumSlice";
import { disableImageSelection } from "../../../../../store/slices/GalleryTabSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const useAddToAlbumModal = ({ setAddToAlbum }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [albumName, setAlbumName] = useState("");
  const [exisitngName, setExisitingName] = useState("");
  const ablumData = useSelector((state) => state.album);
  const selectedImage = useSelector(
    (state) => state.galleryTab.ImageAllTabSelection
  );
  const location = useLocation();

  const isExistingName = ablumData?.resultData?.data?.some(
    ({ title }) => title.toLowerCase() === albumName.toLowerCase()
  );

  useEffect(() => {
    if (isExistingName) {
      setExisitingName("Folder name already exists.");
    }
  }, [albumName]);
  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  useEffect(() => {
    if (ablumData?.status == "idle") {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getAlbums(obj));
    }
  }, []);

  const handleAlbumCreate = async () => {
    let params = {
      title: albumName,
      image_id: selectedImage?.selectedImage,
    };
    if (!isExistingName) {
      const resultAction = await dispatch(createAlbum(params));
      if (createAlbum.fulfilled.match(resultAction)) {
        dispatch(disableImageSelection({ tab: "" }));
        setAlbumName("");
        setAddToAlbum({ popup: false, type: "add" });
        if (currentPath == "album") {
        }
        //FIXME open collapes in album view
        navigate("album");
      }
    } else if (isExistingName) {
      setExisitingName("Folder name already exists.");
    }

    //FIXME redireaction
  };

  const handleAddToAlbum = async (item) => {
    let newItem = {
      ...item,
      images: item?.images ? item?.images : [],
    };
    let selectedImageArray = [...selectedImage?.selectedImage];
    let OldImageSet = newItem.images?.map((item, index) => item.id);
    let reverseOldList = [...OldImageSet].reverse();
    selectedImageArray.forEach((ImageId) => {
      let objWithIdIndex = newItem?.images?.findIndex(
        (obj) => obj.id == ImageId
      );
      if (objWithIdIndex > -1) {
        selectedImageArray.splice(objWithIdIndex, 1);
      }
    });

    let data = {
      album_id: newItem?.id,
      image_id: [...reverseOldList, ...selectedImageArray],
    };

    const resultAction = await dispatch(
      editAlbum({
        data,
        albumData: item,
        imageIds: selectedImage?.selectedImage,
      })
    );
    if (editAlbum.fulfilled.match(resultAction)) {
      dispatch(disableImageSelection({ tab: "" }));
      setAddToAlbum({ popup: false, type: "add" }); //FIXME open collapes in album view
      navigate("album");
    }
    //FIXME redireaction
  };

  const handleCloseModal = () => {
    dispatch(disableImageSelection({ tab: "" }));
    setAddToAlbum({ popup: false, type: "add" });
  };

  return {
    albumName,
    setAlbumName,
    ablumData,
    handleAlbumCreate,
    handleAddToAlbum,
    handleCloseModal,
    exisitngName,
    isExistingName,
  };
};
