import React from "react";
import { useState } from "react";
import Icons from "../../Layouts/Icons";
import Style from "../VideoGallery.module.scss";
import useDarkmode from "../../../utils/utilityHooks/useDarkmode";
import { useVideoItem } from "./useVideoItem";
import Assets from "../../Layouts/Assets";

const VideoItem = ({
  id,
  title,
  content,
  image,
  handleEdit,
  handleDelete,
  handleOpenPreview,
}) => {
  const [selected, setSelected] = useState(true);
  let selectActive = true;

  const { tempCard, isLoaded, videoActions, handleLoad, handleSelectVideo } =
    useVideoItem();
  const imageFormatRegex = /\.(jpeg|jpg|png)$/i;
  let imgData =
    image && imageFormatRegex.test(image) ? image : Assets.cover_img;
  return (
    <>
      <div
        className={`${Style.VideoGallery_item} ${
          videoActions?.isPreSelect && Style.VideoGallery_item_select_active
        }`}
        onClick={() => handleSelectVideo(id, 1)}
      >
        <div className={`${Style.VideoGallery_item_content} `}>
          <img
            src={isLoaded ? imgData :  tempCard}
            className={Style.VideoGallery_item_img}
            onLoad={(e) => handleLoad(e)}
          />
          {videoActions?.isPreSelect ? (
            <>
              <span
                // onClick={() => handleSelectVideo(id, 2)}
                className={`${Style.VideoGallery_item_select} ${
                  videoActions?.selected_Ids?.includes(id) &&
                  Style.VideoGallery_item_selected
                }`}
              >
                {videoActions?.selected_Ids?.includes(id) && (
                  <Icons color="#fff" size={12} icon={"icon-tick"} />
                )}
              </span>
            </>
          ) : (
            <>
              <button
                className={Style.VideoGallery_item_play_btn}
                onClick={() => handleOpenPreview()}
              >
                <Icons
                  color="currentColor"
                  size={44}
                  icon={"icon-slideshow-play"}
                />
              </button>
              <div className={Style.VideoGallery_item_footer}>
                <div className={Style.VideoGallery_item_footer_title}>
                  <h4>{title}</h4>
                  <p>{content}</p>
                </div>
                <div className={Style.VideoGallery_item_footer_btns}>
                  <button
                    className={Style.VideoGallery_item_edit_btn}
                    onClick={() => handleEdit()}
                  >
                    <Icons color="currentColor" size={13} icon={"edit"} />
                  </button>
                  <button
                    className={Style.VideoGallery_item_delete_btn}
                    onClick={() => handleDelete()}
                  >
                    <Icons
                      color="currentColor"
                      size={13}
                      icon={"icon-trash-bin"}
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoItem;
