import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import CircularProgressBar from "../../../utils/CircularProgressLoader";
import Style from "../FileUploadMinimizedBox.module.scss";
import { useItemProgressListener } from "@rpldy/uploady";
import Icons from "../../Layouts/Icons";
import Assets from "../../Layouts/Assets";
import ProgressBar from "react-bootstrap/ProgressBar";

const ImageThumb = ({ image, name, isDupicate, isImageSuccess }) => {
  const { completed } = useItemProgressListener(image?.id) || {
    completed: image?.thumbnail ? 100 : 0,
  };
  const nameRef = useRef();
  const windowDimensions = useDimensions();

  const [charLength, setCharLength] = useState("");

  useEffect(() => {
    setCharLength(nameRef?.current?.offsetWidth / 6);
  }, [windowDimensions.width]);

  const src = image?.thumbnail
    ? image?.thumbnail
    : isDupicate
    ? Assets?.duplicate_icon
    : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg";

  return (
    <li
      // key={item.id}
      className={`${Style.image_uploaded}  ${isDupicate && Style.duplication}
`}
    >
      <figure className={Style.fu_thumbnail}>
        <img src={src} alt="img-thumb" />
      </figure>
      <div className={Style.fu_item_data_right} ref={nameRef}>
        <div className={Style.fu_item_data_right_top}>
          <span className={Style.file_name}>
            {name?.substring(0, charLength)}
            {`${name > charLength ? "..." : ""}`}
          </span>
        </div>
        {!image?.thumbnail && isImageSuccess && (
          <ProgressBar animated now={completed} />
        )}
      </div>
      <div className={`${Style.list_item_actions}`}>
        {/* {!image?.thumbnail && isImageSuccess && (
          <Progress strokeWidth={8} percentage={completed} />
        )} */}
        {image?.thumbnail && isImageSuccess && (
          //FIXME handle invalid files
          <Icons icon={"icon-tick"} size={13} color="green" />
        )}
      </div>
    </li>
  );
};

export default ImageThumb;

const Progress = ({ strokeWidth, percentage }) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "#23CC2B",
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      className={"CircularProgressbar"}
      viewBox="0 0 100 100"
      width={23}
      height={23}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#D7DAE4",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />
    </svg>
  );
};
