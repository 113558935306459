import { useLocation, useParams } from "react-router-dom";

const useRouteUtils = () => {
  const location = useLocation();
  let { albumId, query, option } = useParams();

  const checkIfActiveRoute = (route, exact) => {
    let isActive = false;
    let arry = [
      "/dashboard",
      "/dashboard/favorites",
      "/dashboard/album",
      "/dashboard/slideshow",
      `/dashboard/album/${albumId}`,
      "/dashboard/archive",
      "/dashboard/trash",
      "/dashboard/user-profile",
      "/dashboard/user-profile/members",
      `/dashboard/searchresult/${query}/${option}`,
    ];
    if (route == "/dashboard" && exact) {
      isActive = arry.includes(location.pathname);
    } else {
      isActive = location.pathname.includes(route);
    }
    return isActive;
  };
  return {
    checkIfActiveRoute,
  };
};

export default useRouteUtils;
