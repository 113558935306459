import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  isCreatingLink: "idle",
  isDeletingLink: "idle",
  error: null,
  resultData: {},
  videoActions: {
    isOpenDeleteModal: false,
    isOpenCreationModal: false,
    mode: "add",
    currentData: {},
    selected_Ids: [],
    isPreSelect: false,
  },
  videoCurrentSort: "",
};

export const getVideoLinks = createAsyncThunk(
  "videos/getVideoLinkList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-video?page=${params.page}&&sort_order=${params.slug}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadmoreVideoLinks = createAsyncThunk(
  "videos/loadmoreVideoLinks",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-video?page=${params.page}&&sort_order=${params.slug}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addVideoLink = createAsyncThunk(
  "videos/addVideoLink",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-video`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVideoLink = createAsyncThunk(
  "videos/updateVideoLink",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-video`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVideoLink = createAsyncThunk(
  "videos/deleteVideoLink",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-video`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    openVideoCreationModal: (state, action) => {
      state.videoActions.isOpenCreationModal = action.payload.isOpen;
      state.videoActions.mode = action.payload.mode;
      state.videoActions.currentData = action.payload.currentData;
    },
    openVideoDeleteModal: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        isOpenDeleteModal: action.payload.isOpen,
      };
      if (action?.payload?.id) {
        state.videoActions = {
          ...state.videoActions,
          selected_Ids: [action?.payload?.id],
        };
      }
    },

    openPreSelect: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        isPreSelect: action.payload,
      };
    },
    clearSelectionState: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        isPreSelect: false,
        selected_Ids: [],
      };
    },

    selectAllVideos: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        selected_Ids: action?.payload,
      };
    },
    deSelectAllVideos: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        selected_Ids: [],
      };
    },

    selectVideos: (state, action) => {
      let selectedArray = [...state?.videoActions?.selected_Ids];
      if (!selectedArray?.includes(action.payload)) {
        state.videoActions = {
          ...state.videoActions,
          selected_Ids: [...state?.videoActions?.selected_Ids, action?.payload],
        };
      } else {
        let selected = [...selectedArray];
        let objWithIdIndex = selected.indexOf(action?.payload);
        if (objWithIdIndex > -1) {
          selected.splice(objWithIdIndex, 1);
          selectedArray = selected;
          state.videoActions = {
            ...state?.videoActions,
            selected_Ids: selectedArray,
          };
        }
      }
    },
    openVideoDeleteItemsModal: (state, action) => {
      state.videoActions = {
        ...state.videoActions,
        isOpenDeleteModal: action.payload.isOpen,
      };
    },
    videoSort: (state, action) => {
      state.videoCurrentSort = action.payload;
    },
  },

  extraReducers: {
    [getVideoLinks.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVideoLinks.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = { ...action.payload.data.result };
    },
    [getVideoLinks.rejected]: (state, action) => {
      state.status = "failed";
    },
    [addVideoLink.pending]: (state, action) => {
      state.isCreatingLink = "loading";
    },
    [addVideoLink.fulfilled]: (state, action) => {
      state.isCreatingLink = "succeeded";
      state.resultData = {
        ...state.resultData,
        data: [...state.resultData.data, action.payload.data.result],
      };
    },
    [addVideoLink.rejected]: (state, action) => {
      state.isCreatingLink = "failed";
    },

    [updateVideoLink.pending]: (state, action) => {
      state.isCreatingLink = "loading";
    },
    [updateVideoLink.fulfilled]: (state, action) => {
      state.isCreatingLink = "succeeded";
      let videoIndex = current(state.resultData.data).findIndex(
        (obj) => obj.id == action.payload.data.result.id
      );
      if (videoIndex !== -1) {
        state.resultData.data[videoIndex] = action.payload.data.result;
      }
    },
    [updateVideoLink.rejected]: (state, action) => {
      state.isCreatingLink = "failed";
    },

    [deleteVideoLink.pending]: (state, action) => {
      state.isDeletingLink = "loading";
    },
    [deleteVideoLink.fulfilled]: (state, action) => {
      state.isDeletingLink = "succeeded";
      let videos = current(state.resultData.data);
      let videoIds = [...action?.meta?.arg?.video_id];

      state.resultData = {
        ...state.resultData,
        data: videos.filter((item, index) => !videoIds.includes(item.id)),
      };
    },
    [deleteVideoLink.rejected]: (state, action) => {
      state.isDeletingLink = "failed";
    },

    [loadmoreVideoLinks.pending]: (state, action) => {
      state.status = "loading";
    },
    [loadmoreVideoLinks.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = {
        data: [
          ...state.resultData?.data,
          ...action?.payload?.data?.result?.data,
        ],
        current_page: action?.payload?.data?.result?.current_page,
        total: action?.payload?.data?.result?.total,
        last_page: action?.payload?.data?.result?.last_page,
      };
    },
    [loadmoreVideoLinks.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const {
  videoSort,
  selectVideos,
  openPreSelect,
  selectAllVideos,
  deSelectAllVideos,
  clearSelectionState,
  openVideoDeleteModal,
  openVideoCreationModal,
  openVideoDeleteItemsModal,
} = videoSlice.actions;

export default videoSlice.reducer;
